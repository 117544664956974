export const ActionTypes = {
  SET_PRODUCTS: "SET_PRODUCTS",
  SET_WEBSITES_BY_IMAGE: "SET_WEBSITES_BY_IMAGE",
  SET_WEBSITES_BY_IMAGE_CC: "SET_WEBSITES_BY_IMAGE_CC",
  SET_ALIEXPRESS: "SET_ALIEXPRESS",
  SET_ALIEXPRESS_IMAGE: "SET_ALIEXPRESS_IMAGE",
  SET_AMAZON: "SET_AMAZON",
  SET_WALMART: "SET_WALMART",
  SET_PRODUCT_SEARCH: "SET_PRODUCT_SEARCH",
  SET_GOOGLE: "SET_GOOGLE",
  SELECTED_PRODUCT: "SELECTED_PRODUCT",
  REMOVE_SELECTED_PRODUCT: "REMOVE_SELECTED_PRODUCT",
  SET_ALIEXPRESS_I: "SET_ALIEXPRESS_I",
  SET_AMAZON_I: "SET_AMAZON_I",
  SET_GOOGLE_I: "SET_GOOGLE_I",
  SET_ALIEXPRESS_I_CC: "SET_ALIEXPRESS_I_CC",
  SET_AMAZON_I_CC: "SET_AMAZON_I_CC",
  SET_GOOGLE_I_CC: "SET_GOOGLE_I_CC",
  SET_GOOGLE_MAX: "SET_GOOGLE_MAX",
};
