import React, { useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { renderToString } from "react-dom/server";
import "./WebsiteByImage.css";
import CompareProduct from "./widgets/CompareProduct";
function WebsiteByImage(params) {
  console.log(params);
  function addProductCompare(e, img, title, store) {
    e.stopPropagation();
    var compareProduct = document.createElement("div");
    compareProduct.innerHTML = renderToString(
      <CompareProduct
        oImg={imgParam}
        cImg={img}
        store={store}
        price={[priceParam, "See on website"]}
        title={[
          titleParam.replace(/\+/g, " ").substr(0, 100),
          title.substr(0, 100),
        ]}
      />
    );
    const button = compareProduct.getElementsByClassName("exit-button")[0];
    button.onclick = (e) => {
      e.stopPropagation();
      const element = document.getElementsByClassName(
        "compare-products-overlay"
      )[0];
      element.remove();
    };

    const button2 = compareProduct.getElementsByClassName(
      "compare-products-overlay"
    )[0];
    button2.onclick = (e) => {
      e.stopPropagation();
      const element2 = document.getElementsByClassName(
        "compare-products-overlay"
      )[0];
      element2.remove();
    };

    const button3 = compareProduct.getElementsByClassName(
      "compare-products-widget"
    )[0];
    button3.onclick = (e) => {
      e.stopPropagation();
    };
    document
      .getElementsByClassName("App")[0]
      .prepend(compareProduct.firstChild);
  }
  function removeUnwantedWebsites(products) {
    console.log(products);
    var resultsList = [];
    for (let index = 0; index < products.length; index++) {
      const product = products[index];
      if (
        !product.link.includes("youtube") &&
        !product.link.includes(".gstatic.com/images") &&
        !product.link.includes(".jpeg") &&
        !product.link.includes(".jpg") &&
        !product.link.includes(".png") &&
        !product.link.includes(".webp")
      )
        resultsList.push(product);
    }
    return resultsList;
  }
  const queryParams = new URLSearchParams(window.location.search);
  const titleParam = (queryParams.get("title") || "").replace(/ /g, "+");

  const imgParam = queryParams.get("img");

  const priceParam = queryParams.get("price") || "??";
  const ccParam = queryParams.get("cc") || "us";
  const sliderRef = useRef(null);
  const [slideIndex, setSlideIndex] = useState(0);
  const productsData = removeUnwantedWebsites(params.data);
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    initialSlide: 0,
    beforeChange: (oldIndex, newIndex) => {
      setSlideIndex(newIndex);
    },
  };

  let renderList = productsData.map((product) => {
    if (!("loading" in product)) {
      try {
        var title, img, company, link, flaticon;
        title = product.title;
        img = product.img;
        img = img.replace(/https:https/, "https");
        link = product.link;
        company = product.company;

        if (link.includes("ebay.com")) {
          if (link.includes("?")) link = link + "&";
          else link = link + "?";
          link =
            link +
            "mkrid=711-53200-19255-0&siteid=0&campid=5338967868&customid=&toolid=10001";
        }
        if (link.includes("amazon.")) {
          if (link.includes("?")) link = link + "&";
          else link = link + "?";
          if (ccParam == "se")
            link = link + "linkCode=ll1&tag=priceopediase-21";
          else link = link + "linkCode=ll1&tag=priceopedia-20";
        }

        if (link.includes("walmart")) {
          flaticon = "/images/Walmart_Spark.webp";
        } else if (link.includes("ebay")) {
          flaticon = "/images/ebay_logo_color.webp";
        } else {
          flaticon =
            "https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=" +
            link +
            "&size=64";
        }
        function checkFlatIconSize({ target: img }) {
          if (img.naturalHeight < 32) {
            img.src =
              "https://static.vecteezy.com/system/resources/previews/003/346/398/original/shopping-store-or-market-icon-free-vector.jpg";
          }
        }

        return (
          <div className="card-item">
            <div className="store-product-image">
              <img src={img} alt={title.substr(0, 30)} />
            </div>
            <div className="store-card">
              <div className="store-title-header">
                <img
                  src={flaticon}
                  onLoad={checkFlatIconSize}
                  className="store-logo"
                ></img>
                <div className="store-header-text">
                  <p className="store-company-name">
                    {company.charAt(0).toUpperCase() +
                      company.split(".")[0].slice(1)}
                  </p>
                  <p className="store-potentially-cheaper">
                    POTENTIALLY CHEAPER
                  </p>
                </div>
              </div>
              <p className="store-product-title">{title.substr(0, 50)}</p>
              <div className="store-action-buttons">
                <button
                  onClick={() => window.open(link, "_blank")}
                  className="store-action-button"
                >
                  View Store
                </button>
                <div className="divider-line"></div>
                <button
                  onClick={(e) => {
                    addProductCompare(e, img, title, company);
                  }}
                  className="store-action-button"
                >
                  Compare
                </button>
              </div>
            </div>
          </div>
        );
      } catch (error) {}
    } else {
      return (
        <div className="four wide column">
          <a target="_blank">
            <div className="ui link cards">
              <div className="card">
                <div className="image">
                  <div className="skeleton" />
                </div>

                <div className="content">
                  <div className="skeleton skeleton-text"></div>
                  <div className="skeleton skeleton-text"></div>
                  <div className="skeleton skeleton-text"> </div>
                  <div className="skeleton skeleton-text"></div>
                </div>
              </div>
            </div>
          </a>
        </div>
      );
    }
  });
  return (
    <>
      <div
        className="change-slick-button"
        onClick={() => {
          sliderRef.current.slickNext();
          console.log(sliderRef.current);
        }}
      >
        <img src="https://cdn-icons-png.flaticon.com/512/32/32213.webp"></img>
      </div>
      {slideIndex != 0 && (
        <div
          className="change-slick-button prev"
          onClick={() => sliderRef.current.slickPrev()}
        >
          <img src="https://cdn-icons-png.flaticon.com/512/32/32213.webp"></img>
        </div>
      )}
      <Slider ref={sliderRef} className="slider-div" {...settings}>
        {renderList}
      </Slider>
    </>
  );
}

export default WebsiteByImage;
