import React from "react";
import { Link } from "react-router-dom";
import "../HomePage.css";
import ReactGa from "react-ga4";
import { Event } from "../../additionalFunctions/GoogleAnalyticsFunctions";
function HomePageHeader(params) {
  const getBrowserLink = (browser) => {
    let browserLink = "";
    if (browser == "Chrome") {
      browserLink =
        "https://chrome.google.com/webstore/detail/pricein-a-price-compariso/afipijkhaioeopfolgnagcicgpdlcink/related?hl=sv&authuser=0";
    } else if (browser == "Edge") {
      browserLink =
        "https://chrome.google.com/webstore/detail/pricein-a-price-compariso/afipijkhaioeopfolgnagcicgpdlcink/related?hl=sv&authuser=0";
    } else if (browser == "Safari") {
      browserLink =
        "https://apps.apple.com/se/app/pricein-same-product-cheaper/id6455427237?l=en-GB";
    } else {
      browserLink =
        "https://chrome.google.com/webstore/detail/pricein-a-price-compariso/afipijkhaioeopfolgnagcicgpdlcink/related?hl=sv&authuser=0";
    }

    return browserLink;
  };

  const getBrowser = () => {
    const userAgent = navigator.userAgent;
    console.log("userAgent" + userAgent);
    let browserName = "";
    if (userAgent.indexOf("Edg") > -1) {
      browserName = "Edge";
    } else if (userAgent.indexOf("Chrome") > -1) {
      browserName = "Chrome";
    } else if (userAgent.indexOf("Safari") > -1) {
      browserName = "Safari";
    } else {
      browserName = "Chrome";
    }

    return browserName;
  };

  const browser = getBrowser();
  const browserLink = getBrowserLink(browser);
  return (
    <div className="homepage-header">
      <Link to="/">
        <h1 className="home-logo">Pricein</h1>
      </Link>
      <div className="menu-buttons">
        <Link to="/howtouse">
          <div className="m-button">How to Use</div>
        </Link>
        <Link to="/features">
          <div className="m-button">Benefits</div>
        </Link>
        <Link to="/faq">
          <div className="m-button">FAQ</div>
        </Link>
        <Link to="/about">
          <div className="m-button">About us</div>
        </Link>
        <Link to="/support">
          <div className="m-button">Support</div>
        </Link>
      </div>

      <a
        href={browserLink}
        target="_blank"
        className="downolad-extension-button headerbutton"
        onClick={() => {
          Event("HomePageHeader Button", "BrowserLink", browser, browser);
          console.log("Send Click Event");
        }}
      >
        {browser + " Extension"}
      </a>
    </div>
  );
}

export default HomePageHeader;
