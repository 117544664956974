import React, { useRef } from "react";
import "./Support.css";
import styled from "styled-components";
import HomePageHeader from "../widgets/HomePageHeader";

import emailjs from "@emailjs/browser";
import NavbarMobile from "./NavbarMobile";
function Support() {
  const form = useRef();
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_dw4c3ws",
        "template_9euxuqq",
        form.current,
        "NxF1j87WZqTjXwG9F"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
    document.getElementsByClassName(
      "contact-us-send-button"
    )[0].style.background = "rgb(0, 197, 125)";
    document.getElementsByClassName("contact-us-send-button")[0].value =
      "Email was Send!";
    delay(3000).then(() => {
      document.getElementsByClassName(
        "contact-us-send-button"
      )[0].style.background = "#1a2b6b";
      document.getElementsByClassName("contact-us-send-button")[0].value =
        "Send";
    });
  };
  var windowWidth = window.innerWidth < 1000;
  return (
    <div>
      {windowWidth ? (
        <div className="mobile-front-page-header">
          <a href="/">
            <h1 className="mobile-logo">Pricein</h1>
          </a>
          <NavbarMobile></NavbarMobile>
        </div>
      ) : (
        <HomePageHeader></HomePageHeader>
      )}
      <div className="contact-form-main">
        <div className="about-us-title  content-subject-title support-title">
          Contact Us
        </div>
        <StyledContactForm>
          <form ref={form} onSubmit={sendEmail}>
            <label>Name</label>
            <input type="text" name="user_name" />
            <label>Email</label>
            <input type="email" name="user_email" />
            <label>Subject</label>
            <input type="text" name="subject" />
            <label>Message</label>
            <textarea name="message" />
            <input
              className="contact-us-send-button"
              type="submit"
              value="Send"
            />
          </form>
        </StyledContactForm>
      </div>
    </div>
  );
}

export default Support;

// Styles
const StyledContactForm = styled.div`
  width: 500px;
  form {
    margin-bottom: 150px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    font-size: 16px;
    input {
      width: 100%;
      height: 45px;
      padding: 7px;
      outline: none;
      border-radius: 5px;
      border: 1px solid #e7e8ea;
      &:focus {
        border: 2px solid #375bd2;
      }
    }
    textarea {
      max-width: 100%;
      min-width: 100%;
      width: 100%;
      max-height: 100px;
      min-height: 100px;
      padding: 7px;
      outline: none;
      border-radius: 5px;
      border: 1px solid #e7e8ea;
      &:focus {
        border: 2px solid #375bd2;
      }
    }
    label {
      margin-top: 1rem;
      color: #1a2b6b;
    }
    input[type="submit"] {
      margin-top: 2rem;
      cursor: pointer;
      background: #1a2b6b;
      color: white;
      border: none;
    }
  }
`;
