import React from "react";
import "../HomePage.css";
import HomePageHeader from "../widgets/HomePageHeader.js";
import NavbarMobile from "./NavbarMobile";

function AboutUs() {
  var windowWidth = window.innerWidth < 1000;
  return (
    <div>
      {windowWidth ? (
        <div className="mobile-front-page-header">
          <a href="/">
            <h1 className="mobile-logo">Pricein</h1>
          </a>
          <NavbarMobile></NavbarMobile>
        </div>
      ) : (
        <HomePageHeader></HomePageHeader>
      )}
      <div className="about-us-body">
        <div className="about-us-title content-subject-title">
          Learn More About Us
        </div>
        <div className="about-us-content-body content-box-content">
          Pricein is a revolutionary software company that is dedicated to
          helping people save money on their purchases. We understand that in
          today's economy, every penny counts, and we believe that everyone
          deserves access to the best deals and prices available. At Our team is
          made up of experts in software development, data analysis, and user
          experience design, and we're constantly working to improve our
          software and make it even more effective at helping you save money. We
          believe that everyone deserves access to the best deals and prices,
          and we're committed to making that a reality with Pricein possible.
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
