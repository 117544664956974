import React, { useRef, useState } from "react";
import HomePageHeader from "../widgets/HomePageHeader";
import "./HowToUse.css";
import NavbarMobile from "./NavbarMobile";
/*function HowToUse() {
  const [videOn, setVideoOn] = useState(true);

  const videoRef = useRef(null);
  const videoRef2 = useRef(null);

  const playNext = () => {
    console.log(videOn);
    videoRef.current.pause();
    videoRef2.current.play();
  };

  const togglePlay = () => {
    console.log(videOn);
    if (videOn) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setVideoOn(!videOn);
  };
  const togglePlay2 = () => {
    console.log(videOn);
    if (videOn) {
      videoRef2.current.pause();
    } else {
      videoRef2.current.play();
    }
    setVideoOn(!videOn);
  };
  var windowWidth = window.innerWidth < 1000;
  return (
    <div>
      {windowWidth ? (
        <div className="mobile-front-page-header">
          <a href="/">
            <h1 className="mobile-logo">Pricein</h1>
          </a>
          <NavbarMobile></NavbarMobile>
        </div>
      ) : (
        <HomePageHeader></HomePageHeader>
      )}
      <div className="features-body">
        <h3 className="content-subject-title">How to use in 2 steps</h3>
        <div className="how-to-use-content-boxes">
          <div className="content-box">
            <div className="box-subtitle howtouse">
              1st: Click on the floating icon that appears on the side. Then
              click on "Find Cheaper"
            </div>
            <div className="how-to-use-video-body">
              <video
                ref={videoRef}
                class="tutorial-how-to-use"
                poster="/images/posterimage.png"
                muted
                playsInline
                autoPlay
                onEnded={playNext}
              >
                <source src="/images/extensiontutorial.mp4" type="video/mp4" />
              </video>
              <span class="video-on-off-last" onClick={togglePlay}>
                {videOn && (
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="white"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <g clip-path="url(#clip0_2_213)">
                      <circle
                        cx="16"
                        cy="16"
                        r="15"
                        stroke="#565B6C"
                        stroke-width="2"
                      ></circle>
                      <rect
                        x="20"
                        y="10"
                        width="12"
                        height="2"
                        rx="1"
                        transform="rotate(90 20 10)"
                        fill="#565B6C"
                      ></rect>
                      <rect
                        x="14"
                        y="10"
                        width="12"
                        height="2"
                        rx="1"
                        transform="rotate(90 14 10)"
                        fill="#565B6C"
                      ></rect>
                    </g>
                    <defs>
                      <clipPath id="clip0_2_213">
                        <rect width="32" height="32" fill="white"></rect>
                      </clipPath>
                    </defs>
                  </svg>
                )}
                {!videOn && (
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="white"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <g clip-path="url(87489656-c327-45fb-9b94-d0dd4ddf69d1)">
                      <circle
                        cx="16"
                        cy="16"
                        r="15"
                        stroke="#565B6C"
                        stroke-width="2"
                      ></circle>
                      <path
                        d="M21.7003 15.1601C22.31 15.5541 22.31 16.4459 21.7003 16.8399L14.234 21.6648C13.5686 22.0948 12.6913 21.6172 12.6913 20.825L12.6913 11.175C12.6913 10.3828 13.5686 9.90516 14.234 10.3352L21.7003 15.1601Z"
                        fill="#565B6C"
                      ></path>
                    </g>
                    <defs>
                      <clipPath id="87489656-c327-45fb-9b94-d0dd4ddf69d1">
                        <rect width="32" height="32" fill="white"></rect>
                      </clipPath>
                    </defs>
                  </svg>
                )}
              </span>
            </div>
          </div>

          <div className="content-box">
            <div className="box-subtitle howtouse">
              2nd: Refine your search by applying price filters.
            </div>
            <div className="how-to-use-video-body">
              <video
                ref={videoRef2}
                class="tutorial-how-to-use"
                poster="/images/posterimage.png"
                muted
                playsInline
              >
                <source src="/images/HowToUse3.mp4" type="video/mp4" />
              </video>
              <span class="video-on-off-last" onClick={togglePlay2}>
                {videOn && (
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="white"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <g clip-path="url(#clip0_2_213)">
                      <circle
                        cx="16"
                        cy="16"
                        r="15"
                        stroke="#565B6C"
                        stroke-width="2"
                      ></circle>
                      <rect
                        x="20"
                        y="10"
                        width="12"
                        height="2"
                        rx="1"
                        transform="rotate(90 20 10)"
                        fill="#565B6C"
                      ></rect>
                      <rect
                        x="14"
                        y="10"
                        width="12"
                        height="2"
                        rx="1"
                        transform="rotate(90 14 10)"
                        fill="#565B6C"
                      ></rect>
                    </g>
                    <defs>
                      <clipPath id="clip0_2_213">
                        <rect width="32" height="32" fill="white"></rect>
                      </clipPath>
                    </defs>
                  </svg>
                )}
                {!videOn && (
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="white"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <g clip-path="url(87489656-c327-45fb-9b94-d0dd4ddf69d1)">
                      <circle
                        cx="16"
                        cy="16"
                        r="15"
                        stroke="#565B6C"
                        stroke-width="2"
                      ></circle>
                      <path
                        d="M21.7003 15.1601C22.31 15.5541 22.31 16.4459 21.7003 16.8399L14.234 21.6648C13.5686 22.0948 12.6913 21.6172 12.6913 20.825L12.6913 11.175C12.6913 10.3828 13.5686 9.90516 14.234 10.3352L21.7003 15.1601Z"
                        fill="#565B6C"
                      ></path>
                    </g>
                    <defs>
                      <clipPath id="87489656-c327-45fb-9b94-d0dd4ddf69d1">
                        <rect width="32" height="32" fill="white"></rect>
                      </clipPath>
                    </defs>
                  </svg>
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}*/

function HowToUse() {
  const [stepNumber, setStepNumber] = useState(1);
  const videoUrls = [
    "extensiontutorial.mp4",
    "PriceCompareExample.mp4",
    "FindByImage.mp4",
  ];
  return (
    <div className="howitworks-body">
      <div className="mobile-front-page-header">
        <a href="/">
          <h1 className="mobile-logo" style={{ color: "#1967D2" }}>
            Pricein
          </h1>
        </a>
        <NavbarMobile></NavbarMobile>
      </div>
      <div className="howitworks-all">
        <div className="howitworks-title-content">
          <div className="howitworks-title-main">Lets get this over with!</div>
          <div className="howitworks-title-subtitle">How it works</div>
        </div>
        <div className="howitworks-content">
          <div className="howitworks-instructions">
            <div className="howitworks-progressbar">
              <div
                className={"howitworks-progressbar-blue" + " step" + stepNumber}
              ></div>
            </div>
            <div className="howitworks-text-content">
              <div className="howitworks-instruction-content">
                <div
                  onClick={() => {
                    setStepNumber(1);
                  }}
                  className={
                    stepNumber == 1
                      ? "howitworks-instruction-title current"
                      : "howitworks-instruction-title"
                  }
                >
                  1. Product finder
                </div>
                {stepNumber == 1 && (
                  <>
                    <div className="howitworks-instruction-explanation">
                      When you're on a product page a blue icon will appear on
                      the side and if you're looking to find the same product
                      for a lower price, follow these simple steps.
                    </div>
                    <div
                      className="howitworks-next-button"
                      onClick={() => {
                        setStepNumber(2);
                      }}
                    >
                      Next Step
                    </div>
                  </>
                )}
              </div>
              <div className="howitworks-instruction-content">
                <div
                  onClick={() => {
                    setStepNumber(2);
                  }}
                  className={
                    stepNumber == 2
                      ? "howitworks-instruction-title current"
                      : "howitworks-instruction-title"
                  }
                >
                  2. Automatic Price Compare
                </div>
                {stepNumber == 2 && (
                  <>
                    <div className="howitworks-instruction-explanation">
                      While shopping on websites like Amazon, QVC, Target, and
                      1000+ more, our service automatically scans for the best
                      price of any item on your screen, conveniently displaying
                      it for you.
                    </div>
                    <div
                      className="howitworks-next-button"
                      onClick={() => {
                        setStepNumber(3);
                      }}
                    >
                      Next Step
                    </div>
                  </>
                )}
              </div>
              <div className="howitworks-instruction-content">
                <div
                  onClick={() => {
                    setStepNumber(3);
                  }}
                  className={
                    stepNumber == 3
                      ? "howitworks-instruction-title current"
                      : "howitworks-instruction-title"
                  }
                >
                  3. Find product by image
                </div>
                {stepNumber == 3 && (
                  <>
                    <div className="howitworks-instruction-explanation">
                      Whenever an image of a desired product catches your eye,
                      simply right-click and select "Find Product." Our
                      cutting-edge AI technology will then locate similar items
                      for you.
                    </div>
                    <div
                      className="howitworks-next-button"
                      onClick={() => {
                        setStepNumber(1);
                      }}
                    >
                      First Step
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="howitworks-video">
            {stepNumber == 1 ? (
              <video class="tutorial-howtouse" autoPlay muted playsInline loop>
                <source src={"/images/" + videoUrls[0]} type="video/mp4" />
              </video>
            ) : stepNumber == 2 ? (
              <div>
                <video
                  class="tutorial-howtouse"
                  autoPlay
                  muted
                  playsInline
                  loop
                >
                  <source src={"/images/" + videoUrls[1]} type="video/mp4" />
                </video>
              </div>
            ) : (
              <video class="tutorial-howtouse" autoPlay muted playsInline loop>
                <source src={"/images/" + videoUrls[2]} type="video/mp4" />
              </video>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HowToUse;
