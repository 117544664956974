import React from "react";
import "./BasicLoading.scss";
function BasicLoading(color) {
  console.log(color);
  return (
    <div class="showbox">
      <div class="loaders">
        <svg class="circular" viewBox="25 25 50 50">
          <circle
            class={"path " + color.color}
            cx="50"
            cy="50"
            r="20"
            fill="none"
            stroke-width="6"
            stroke-miterlimit="10"
          />
        </svg>
      </div>
    </div>
  );
}

export default BasicLoading;
