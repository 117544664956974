import React, { useState } from "react";
import "./StoreListing.css";
import { useDispatch, useSelector } from "react-redux";
import { FaStarHalf, FaStar, FaRegStar, FaStarHalfAlt } from "react-icons/fa";
import { renderToString } from "react-dom/server";
import CompareProduct from "./widgets/CompareProduct";
import { Link, useHistory } from "react-router-dom";
import Header from "./Header";
function StoreListing() {
  const amazonProductsI = useSelector(
    (state) => state.amazonProductsI.products
  );

  const aliexpressImageProducts = useSelector(
    (state) => state.aliexpressProductsImage.products
  );
  const aliexpressIProducts = useSelector(
    (state) => state.aliexpressProductsI.products
  );
  const googleProductsI = useSelector(
    (state) => state.googleProductsI.products
  );

  const aliexpressICCProducts = useSelector(
    (state) => state.aliexpressProductsICC.products
  );
  const googleProductsICC = useSelector(
    (state) => state.googleProductsICC.products
  );
  const amazonProductsICC = useSelector(
    (state) => state.amazonProductsICC.products
  );

  const googleProductsMax = useSelector(
    (state) => state.googleProductsMax.products
  );
  const amazonProducts = mixProducts(
    mixProducts(
      useSelector((state) => state.amazonProducts.products),
      amazonProductsI
    ),
    amazonProductsICC
  );
  const walmartProducts = useSelector(
    (state) => state.walmartProducts.products
  );

  const googleProducts = mixProducts(
    googleProductsMax,
    mixProducts(
      mixProducts(
        useSelector((state) => state.googleProducts.products),
        googleProductsI
      ),
      googleProductsICC
    )
  );
  const aliexpressProducts = mixProducts(
    aliexpressImageProducts,
    mixProducts(
      useSelector((state) => state.aliexpressProducts.products),
      aliexpressIProducts
    )
  );

  const storeName =
    new URLSearchParams(window.location.search).get("storename") || "amazon";

  var products = [];
  var ProductsAS = [];
  const [sortAfterBool, setSortAfterBool] = useState(false);
  const [sortVal, setSortVal] = useState(1);
  function sortAfterClicked(num) {
    console.log("click");
    setSortAfterBool(false);
    setSortVal(num);
  }
  const sortBySimilarityScore = (a, b) => {
    if (!a.similarityScore) return 1;
    if (!b.similarityScore) return -1;
    return a.similarityScore - b.similarityScore;
  };
  function addProductCompare(e, img, title, price, store) {
    e.stopPropagation();
    var compareProduct = document.createElement("div");
    compareProduct.innerHTML = renderToString(
      <CompareProduct
        oImg={imgParam}
        cImg={img}
        price={[priceParam, price]}
        store={store}
        title={[
          titleParam.replace(/\+/g, " ").substr(0, 100),
          title.substr(0, 100),
        ]}
      />
    );
    const button = compareProduct.getElementsByClassName("exit-button")[0];
    button.onclick = (e) => {
      e.stopPropagation();
      const element = document.getElementsByClassName(
        "compare-products-overlay"
      )[0];
      element.remove();
    };

    const button2 = compareProduct.getElementsByClassName(
      "compare-products-overlay"
    )[0];
    button2.onclick = (e) => {
      e.stopPropagation();
      const element2 = document.getElementsByClassName(
        "compare-products-overlay"
      )[0];
      element2.remove();
    };

    const button3 = compareProduct.getElementsByClassName(
      "compare-products-widget"
    )[0];
    button3.onclick = (e) => {
      e.stopPropagation();
    };
    document
      .getElementsByClassName("App")[0]
      .prepend(compareProduct.firstChild);
  }
  function getSortAfterTitle() {
    switch (sortVal) {
      case 1:
        return "Best Match";
        break;
      case 2:
        return "Average Rating";
        break;
      case 3:
        return "Price Low - High";
        break;
      case 4:
        return "Price High - Low";
        break;
      case 5:
        return "Number of Reviews";
        break;
    }
  }
  function compareReviews(a, b) {
    var aprice = "review" in a ? a.review : a.averageRating;
    var bprice = "review" in b ? b.review : b.averageRating;
    if (Number(aprice) > Number(bprice)) {
      return -1;
    }
    if (Number(aprice) < Number(bprice)) {
      return 1;
    }
    return 0;
  }
  function comparePriceLowToHigh(a, b) {
    var aprice = (
      "price" in a ? a.price : a.productMinPrice.value || "0"
    ).toString();
    var bprice = (
      "price" in b ? b.price : b.productMinPrice.value || "0"
    ).toString();
    aprice = aprice.replace(/[^\d.-]/g, "").replace(/,/, "");
    bprice = bprice.replace(/[^\d.-]/g, "").replace(/,/, "");
    if (Number(aprice) < Number(bprice)) {
      return -1;
    }
    if (Number(aprice) > Number(bprice)) {
      return 1;
    }
    return 0;
  }
  function comparePriceHighToLow(a, b) {
    var aprice = (
      "price" in a ? a.price : a.productMinPrice.value || "0"
    ).toString();
    var bprice = (
      "price" in b ? b.price : b.productMinPrice.value || "0"
    ).toString();
    aprice = aprice.replace(/[^\d.-]/g, "").replace(/,/, "");
    bprice = bprice.replace(/[^\d.-]/g, "").replace(/,/, "");
    if (Number(aprice) > Number(bprice)) {
      return -1;
    }
    if (Number(aprice) < Number(bprice)) {
      return 1;
    }
    return 0;
  }
  function compareReviewsAmount(a, b) {
    var aprice = (
      "reviewAmount" in a ? a.reviewAmount : a.totalOrders || "0"
    ).toString();
    var bprice = (
      "reviewAmount" in b ? b.reviewAmount : b.totalOrders || "0"
    ).toString();
    aprice = aprice.replace(/[^\d.-]/g, "").replace(/,/, "");
    bprice = bprice.replace(/[^\d.-]/g, "").replace(/,/, "");
    if (Number(aprice) > Number(bprice)) {
      return -1;
    }
    if (Number(aprice) < Number(bprice)) {
      return 1;
    }
    return 0;
  }
  var storeTitle = "";

  function mixProducts(...arrays) {
    const mixedArray = [];
    const maxLength = Math.max(...arrays.map((arr) => arr.length));

    for (let i = 0; i < maxLength; i++) {
      for (const array of arrays) {
        if (i < array.length) {
          mixedArray.push(array[i]);
        }
      }
    }

    return mixedArray;
  }

  function roundHalf(num) {
    return Math.round(num * 2) / 2;
  }
  function starRating(review) {
    const reviewHalf = roundHalf(review);
    switch (reviewHalf) {
      case 0:
        return [];
      case 0.5:
        return [1];
      case 1:
        return [0];
      case 1.5:
        return [0, 1];
      case 2.0:
        return [0, 0];
      case 2.5:
        return [0, 0, 1];
      case 3.0:
        return [0, 0, 0];
      case 3.5:
        return [0, 0, 0, 1];
      case 4.0:
        return [0, 0, 0, 0];
      case 4.5:
        return [0, 0, 0, 0, 1];
      case 5:
        return [0, 0, 0, 0, 0];
    }
    return [];
  }

  if (storeName == "amazon") {
    storeTitle = "Amazon";
    products = products.concat(amazonProducts);
  }
  if (storeName == "allstores") {
    products = mixProducts(
      [].concat(googleProducts),
      [].concat(amazonProducts),
      [].concat(aliexpressProducts),
      [].concat(googleProductsICC),
      [].concat(walmartProducts),
      [].concat(googleProductsI),
      [].concat(amazonProductsI)
    ).sort(sortBySimilarityScore);

    storeTitle = "All Stores";
  }
  if (storeName == "aliexpress") {
    products = products.concat(aliexpressProducts);
    storeTitle = "Aliexpress";
  }
  if (sortVal == 1) {
    ProductsAS = products;
  }
  if (sortVal == 2) {
    console.log("2asdasd");
    ProductsAS = products.sort(compareReviews);
  }
  if (sortVal == 3) {
    ProductsAS = products.sort(comparePriceLowToHigh);
  }
  if (sortVal == 4) {
    ProductsAS = products.sort(comparePriceHighToLow);
  }
  if (sortVal == 5) {
    ProductsAS = products.sort(compareReviewsAmount);
  }

  let renderList = ProductsAS.map((product) => {
    if (!("loading" in product)) {
      var title,
        img,
        price,
        link,
        domain,
        review,
        ratingAvrage,
        reviewAmount,
        store;
      if ("productMinPrice" in product) {
        title = product.title;
        img = product.imageUrl;
        img = img.replace(/https:https/, "https");
        price = "$" + product.productMinPrice.value;
        link = "https://www.aliexpress.com/item/" + product.productId + ".html";
        domain = "aliexpress.com";
        review = starRating(Number(product.averageRating));
        ratingAvrage = Number(product.averageRating);
        reviewAmount = "(" + product.totalOrders + " orders)";
      } else {
        title = product.title;
        img = product.img;
        img = img.replace(/https:https/, "https");
        price = product.price;
        link = product.link;
        review = starRating(Number(product.review));
        ratingAvrage = Number(product.review);
        reviewAmount = product.reviewAmount || "";
        if (link.includes("shopping/product/")) {
          domain = "shopping.google";
        } else {
          try {
            if (link.includes("google.com")) {
              domain = new URL(link.split("url=")[1]).hostname.replace(
                /www./,
                ""
              );
            } else {
              domain = new URL(link).hostname.replace(/www./, "");
            }
          } catch (error) {
            domain = link;
          }
        }
      }
      if (link.includes("click.aliexpress")) {
        domain = "aliexpress.com";
      }
      if (link.includes("api.aliseeks.com")) {
        domain = "aliexpress.com";
      }
      var storeReverse = domain.split("").reverse().join("");
      store = storeReverse
        .substr(
          storeReverse.indexOf(".") + 1,
          storeReverse.length - storeReverse.indexOf(".")
        )
        .split("")
        .reverse()
        .join("");
      ratingAvrage = ratingAvrage == "5" ? "5.0" : ratingAvrage;
      // const { id, title, image, price, category } = product;
      reviewAmount =
        reviewAmount.includes("(") || reviewAmount == ""
          ? reviewAmount
          : "(" + reviewAmount + ")";
      return (
        <div className="four wide column">
          <a onClick={() => window.open(link, "_blank")} target="_blank">
            <div className="ui link cards">
              <div className="cardd">
                <div className="overlay">
                  <div className="action-buttons">
                    <div className="action-button-container">
                      <div className="action-button">
                        <img src="https://cdn-icons-png.flaticon.com/512/1170/1170678.png"></img>
                      </div>
                      <div className="tool-tip">Go to store</div>
                    </div>
                    <div className="action-button-container">
                      <div
                        className="action-button"
                        onClick={(e) =>
                          addProductCompare(e, img, title, price, store)
                        }
                      >
                        <img src="https://cdn-icons-png.flaticon.com/512/3793/3793562.png"></img>
                      </div>

                      <div className="tool-tip">
                        Compare to original product
                      </div>
                    </div>
                    <div className="action-button-container">
                      <div className="action-button">
                        <img src="https://cdn-icons-png.flaticon.com/512/5186/5186087.png"></img>
                      </div>
                      <div className="tool-tip">Save for later</div>
                    </div>
                  </div>
                </div>
                <div className="image">
                  <img src={img} alt={title} />
                </div>
                <div className="line-div"></div>
                <div className="meta">{domain}</div>
                <div className="header">{title.substr(0, 50)}</div>
                <div className="rating">
                  {ratingAvrage !== 0 && (
                    <p className="avarage-rating">{ratingAvrage}</p>
                  )}
                  {review.map((x, idx) => (
                    <div>
                      {x == 0 && <FaStar size={12} color="black"></FaStar>}
                      {x == 1 && (
                        <FaStarHalf size={12} color="black"></FaStarHalf>
                      )}
                    </div>
                  ))}

                  {reviewAmount != "" && (
                    <p className="number-rating">{reviewAmount}</p>
                  )}
                </div>

                <div className="meta price">{price} </div>
              </div>
            </div>
          </a>
        </div>
      );
    } else {
      return (
        <div className="four wide column">
          <a target="_blank">
            <div className="ui link cards">
              <div className="cardd">
                <div className="image">
                  <div className="skeleton" />
                </div>

                <div className="content">
                  <div className="skeleton skeleton-text"></div>
                  <div className="skeleton skeleton-text"></div>
                  <div className="skeleton skeleton-text"> </div>
                  <div className="skeleton skeleton-text"></div>
                </div>
              </div>
            </div>
          </a>
        </div>
      );
    }
  });
  const queryParams = new URLSearchParams(window.location.search);
  const titleParam = queryParams.get("title").replace(/ /g, "+");

  const imgParam = queryParams.get("img");

  const priceParam = queryParams.get("price") || "??";
  const ccParam = queryParams.get("cc") || "us";
  const goBackUrl =
    "/product/finder/" +
    "?load=false" +
    "&img=" +
    imgParam +
    "&title=" +
    titleParam +
    "&price=" +
    priceParam +
    "&cc=" +
    ccParam;
  return (
    <div className="store-listing">
      <Header></Header>
      <div className="header">
        <div className="go-back">
          <Link to={goBackUrl}>
            <div className="back-circle">
              <img src="https://cdn-icons-png.flaticon.com/512/109/109618.png"></img>
            </div>
          </Link>
          <h4 className="title-store-listing">{storeTitle + " Results"}</h4>
        </div>
        <div className="sort-after">
          <div
            onClick={() => setSortAfterBool(!sortAfterBool)}
            className="sort-after-texts"
          >
            <p className="sort-after-p">Sort after:</p>
            <p className="sort-after-p sort-after-text-button">
              {getSortAfterTitle()}
            </p>
            <div class="arrow-down" />
          </div>
          {sortAfterBool && (
            <div className="sort-after-container">
              <button
                onClick={() => sortAfterClicked(1)}
                className="sort-after-button"
              >
                Best Match
              </button>

              <button
                onClick={() => sortAfterClicked(3)}
                className="sort-after-button"
              >
                Price Low - High
              </button>
              <button
                onClick={() => sortAfterClicked(4)}
                className="sort-after-button"
              >
                Price High - Low
              </button>
              <button
                onClick={() => sortAfterClicked(2)}
                className="sort-after-button"
              >
                Avrage Rating
              </button>
              <button
                onClick={() => sortAfterClicked(5)}
                className="sort-after-button"
              >
                {storeName == "aliexpress" ? "Orders" : "Number of Reviews"}
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="product-container">
        <div className="ui grid container">{renderList}</div>
      </div>
    </div>
  );
}

export default StoreListing;
