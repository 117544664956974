import { ActionTypes } from "../constants/action-types";

export const setProducts = (products) => {
  return {
    type: ActionTypes.SET_PRODUCTS,
    payload: products,
  };
};
export const setWebsiteByImage = (products) => {
  return {
    type: ActionTypes.SET_WEBSITES_BY_IMAGE,
    payload: products,
  };
};
export const setWebsiteByImageCC = (products) => {
  return {
    type: ActionTypes.SET_WEBSITES_BY_IMAGE_CC,
    payload: products,
  };
};
export const setGoogle = (products) => {
  return {
    type: ActionTypes.SET_GOOGLE,
    payload: products,
  };
};
export const setAmazon = (products) => {
  return {
    type: ActionTypes.SET_AMAZON,
    payload: products,
  };
};

export const setWalmart = (products) => {
  return {
    type: ActionTypes.SET_WALMART,
    payload: products,
  };
};
export const setProductSearch = (products) => {
  return {
    type: ActionTypes.SET_PRODUCT_SEARCH,
    payload: products,
  };
};
export const setAliexpressImage = (products) => {
  return {
    type: ActionTypes.SET_ALIEXPRESS_IMAGE,
    payload: products,
  };
};

export const setAliexpress = (products) => {
  return {
    type: ActionTypes.SET_ALIEXPRESS,
    payload: products,
  };
};

export const selectedProduct = (product) => {
  return {
    type: ActionTypes.SELECTED_PRODUCT,
    payload: product,
  };
};
export const removeSelectedProduct = () => {
  return {
    type: ActionTypes.REMOVE_SELECTED_PRODUCT,
  };
};

export const setGoogleI = (products) => {
  return {
    type: ActionTypes.SET_GOOGLE_I,
    payload: products,
  };
};
export const setAmazonI = (products) => {
  return {
    type: ActionTypes.SET_AMAZON_I,
    payload: products,
  };
};

export const setAliexpressI = (products) => {
  return {
    type: ActionTypes.SET_ALIEXPRESS_I,
    payload: products,
  };
};

export const setGoogleICC = (products) => {
  return {
    type: ActionTypes.SET_GOOGLE_I_CC,
    payload: products,
  };
};

export const setGoogleMax = (products) => {
  return {
    type: ActionTypes.SET_GOOGLE_MAX,
    payload: products,
  };
};

export const setAmazonICC = (products) => {
  return {
    type: ActionTypes.SET_AMAZON_I_CC,
    payload: products,
  };
};

export const setAliexpressICC = (products) => {
  return {
    type: ActionTypes.SET_ALIEXPRESS_I_CC,
    payload: products,
  };
};
