import React from "react";
import HomePageHeader from "../widgets/HomePageHeader";
import "./Features.css";
import NavbarMobile from "./NavbarMobile";
function Features() {
  var windowWidth = window.innerWidth < 1000;
  return (
    <div>
      {windowWidth ? (
        <div className="mobile-front-page-header">
          <a href="/">
            <h1 className="mobile-logo">Pricein</h1>
          </a>
          <NavbarMobile></NavbarMobile>
        </div>
      ) : (
        <HomePageHeader></HomePageHeader>
      )}
      <div className="features-body">
        <h3 className="content-subject-title">
          Benefits of using before purchasing
        </h3>
        <div className="features-content-boxes">
          <div className="content-box">
            <div className="content-box-icon">
              <img src="/images/coupon.webp" alt="coupon logo image"></img>
            </div>
            <div className="box-subtitle">Save 20% on your purchase</div>
            <div className="content-box-content">
              During our testing, we found that we saved an average of 20% on
              our purchases by using our Chrome extension to find the same
              product at a cheaper price on other websites.
            </div>
          </div>
          <div className="content-box">
            <div className="content-box-icon">
              <img src="/images/shopify.webp" alt="shopify icon "></img>
            </div>
            <div className="box-subtitle">
              Identify copycats and dropshippers
            </div>
            <div className="content-box-content">
              If the product you want to buy is a copycat or dropshipping item,
              it may already exist on many different online stores. Our shopping
              tool can help you find cheaper options, including the product
              directly from the manufacturer.
            </div>
          </div>
          <div className="content-box">
            <div className="content-box-icon">
              <img src="/images/factory.webp" alt="factory icon "></img>
            </div>
            <div className="box-subtitle">Find product at manufacturer</div>
            <div className="content-box-content">
              We can help you find the most similar products available on
              AliExpress if you are looking to purchase a product at a lower
              price directly from the manufacturer or if you wish to sell the
              product.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Features;
