import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./StoreContainer.css";
import BasicLoading from "./widgets/BasicLoading";
function StoreContainer(params) {
  var Loadings = params.loading;
  const mobileMode = params.mobileMode;
  var ProductResults;
  console.log("Storecontainer mobile mode", mobileMode);
  const LoadingClassAmazon = Loadings[0] == 1 ? " Loading" : "";
  const LoadingClassOther = Loadings[1] == 1 ? " Loading" : "";
  const LoadingClassAliexpress = Loadings[2] == 1 ? " Loading" : "";
  const LoadingClassStoresByImage = Loadings[3] == 1 ? " Loading" : "";

  const amazonProducts = useSelector((state) => state.amazonProducts.products);
  const WebsiteByImageProducts = useSelector(
    (state) => state.websiteByImageProducts.products
  );
  const aliexpressImageProducts = useSelector(
    (state) => state.aliexpressProductsImage.products
  );
  const googleProducts = useSelector((state) => state.googleProducts.products);
  const aliexpressProducts = useSelector(
    (state) => state.aliexpressProducts.products
  );

  ProductResults =
    aliexpressProducts.length +
    aliexpressImageProducts.length +
    amazonProducts.length +
    WebsiteByImageProducts.length +
    googleProducts.length;

  const queryParams = new URLSearchParams(window.location.search);
  const titleParam = queryParams.get("title") || "";
  const imgonlyParam = (queryParams.get("imgonly") || "false") == "true";
  function scrollFunc(cls) {
    const yOffset = -100;
    var elmntToView = document.getElementsByClassName(cls)[0];
    if (elmntToView) {
      const y =
        elmntToView.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  }
  const resChars = mobileMode ? 12 : 30;
  const resultsTitle =
    titleParam.length > resChars
      ? titleParam.substr(0, resChars) + "..."
      : titleParam;
  if (!mobileMode) {
    return (
      <>
        <div className="store-container">
          <div className="search-results">
            {!imgonlyParam ? (
              <h2>{"Results for: “" + resultsTitle + "”"}</h2>
            ) : (
              <h2>Results of similar products</h2>
            )}
            <p className="search-results-p">{ProductResults + " Results"}</p>
          </div>
          <div className="card-container">
            <div
              onClick={() => scrollFunc("other-store-section-container")}
              className={"card container other-stores" + LoadingClassOther}
            >
              {LoadingClassOther != "" && (
                <div className="loading-animation">
                  {" "}
                  <BasicLoading color="white"></BasicLoading>
                </div>
              )}
              <div class="store-text other-store-text">All Stores</div>
              <div className="other-store-images">
                <img
                  src="/images/walmart-logo-black-transparent.webp"
                  alt="walmart logo image"
                  className="other-store-walmart-logo"
                ></img>
                <img
                  src="/images/ebay-logo-black-transparent.webp"
                  alt="ebay logo image"
                  className="other-store-ebay-logo"
                ></img>
              </div>
            </div>

            <div
              onClick={() => scrollFunc("amazon-section-container")}
              className={"card container amazon" + LoadingClassAmazon}
            >
              {LoadingClassAmazon != "" && (
                <div className="loading-animation">
                  <BasicLoading color="white"></BasicLoading>
                </div>
              )}
              <div class="store-text">Amazon</div>
              <img
                src="/images/01_amazon_smile_RGB_SQUID._TTW_.webp"
                alt="amazon logo image"
                className="amazon-logo"
              ></img>
            </div>
            {/* <div
              onClick={() => scrollFunc("storesByImageTitle")}
              className={"card container amazon" + LoadingClassStoresByImage}
            >
              {LoadingClassStoresByImage != "" && (
                <div className="loading-animation">
                  <BasicLoading color="white"></BasicLoading>
                </div>
              )}
            
              <div class="store-text other-store-text">Stores By Image</div>
            
              <div className="other-store-images">
                <img
                  src="/images/walmart-logo-black-transparent.webp"
                  alt="walmart logo image"
                  className="other-store-walmart-logo"
                ></img>
                <img
                  src="/images/ebay-logo-black-transparent.webp"
                  alt="ebay logo image"
                  className="other-store-ebay-logo"
                ></img>
              </div>
            </div>
  */}
            <div
              onClick={() => scrollFunc("aliexpress-section-container")}
              className={"card container aliexpress" + LoadingClassAliexpress}
            >
              {LoadingClassAliexpress != "" && (
                <div className="loading-animation">
                  {" "}
                  <BasicLoading color="white"></BasicLoading>
                </div>
              )}
              <div class="store-text aliexpress-text">Manufacturing</div>
              <img
                src="/images/aliexpresslogo.webp"
                alt="aliexpress logo image"
                className="aliexpress-logo"
              ></img>
            </div>
          </div>
        </div>
        <div className="line-div-divider"></div>
      </>
    );
  } else {
    return (
      <>
        <div className="store-container-mobile">
          <div className="search-results-mobile">
            {!imgonlyParam ? (
              <h2>{'Results for: "' + resultsTitle + '"'}</h2>
            ) : (
              <h2>Results of similar products</h2>
            )}
            <p className="search-results-p">{ProductResults + " Results"}</p>
          </div>
          <div className="line-div-divider" />
          <div className="mobile-card-container">
            <div className="mobile-loading-card">
              <img src="images/allstoresimg.webp"></img>
              <div className="mobile-store-text">All stores</div>
            </div>
            <div className="mobile-loading-card">
              <img src="images/amazonfavicon.webp"></img>
              <div className="mobile-store-text">Amazon</div>
            </div>
          </div>
          <div className="line-div-divider" />
        </div>
      </>
    );
  }
}

export default StoreContainer;
