import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from "react-router-dom";
import ProductListing from "./containers/ProductListing";
import Header from "./containers/Header";
import "./App.css";
import ProductDetails from "./containers/ProductDetails";
import StoreListing from "./containers/StoreListing";
import ScrollToTop from "./additionalFunctions/ScrollToTop";
import HomePage from "./containers/HomePage";
import { FooterContainer } from "./containers/footer/containers/footer";
import AboutUs from "./containers/navbarpages/AboutUs";
import Privacy from "./containers/navbarpages/Privacy";
import Support from "./containers/navbarpages/Support";
import FAQ from "./containers/navbarpages/FAQ";
import HowToUse from "./containers/navbarpages/HowToUse";
import Features from "./containers/navbarpages/Features";
import IOSInstallPage from "./containers/IOSInstallPage";
import PriceCompare from "./containers/PriceCompare";
import {
  initGA,
  PageView,
} from "./additionalFunctions/GoogleAnalyticsFunctions";
import ExtensionRedirect from "./containers/ExtensionRedirect";
function App() {
  const TRACKING_ID = "G-3KB37JWVNZ";
  useEffect(() => {
    PageView();
  }, []);

  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Switch>
          <Route path="/product/finder/" exact>
            <ProductListing></ProductListing>
          </Route>
          <Route path="/" exact component={HomePage} />
          <Route path="/pc" exact component={PriceCompare} />
          <Route path="/about" exact component={AboutUs} />
          <Route path="/privacy" exact component={Privacy} />
          <Route path="/support" exact component={Support} />
          <Route path="/faq" exact component={FAQ} />
          <Route path="/features" exact component={Features} />
          <Route path="/howtouse" exact component={HowToUse} />
          <Route path="/iosinstall" exact component={IOSInstallPage} />
          <Route path="/extensionlink" exact component={ExtensionRedirect} />

          <Route
            path="/product/finder/storelisting/"
            exact
            component={StoreListing}
          />
          <Route>404 Not Found!</Route>
        </Switch>
        <FooterContainer></FooterContainer>
      </Router>
    </div>
  );
}

export default App;
