import { ActionTypes } from "../constants/action-types";
const intialState = {
  products: [],
};

export const productsReducer = (state = intialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_PRODUCTS:
      return { ...state, products: payload };
    default:
      return state;
  }
};
export const productsReducerAliexpress = (
  state = intialState,
  { type, payload }
) => {
  switch (type) {
    case ActionTypes.SET_ALIEXPRESS:
      return { ...state, products: payload };
    default:
      return state;
  }
};
export const productsReducerWalmart = (
  state = intialState,
  { type, payload }
) => {
  switch (type) {
    case ActionTypes.SET_WALMART:
      return { ...state, products: payload };
    default:
      return state;
  }
};
export const productsReducerProductSearch = (
  state = intialState,
  { type, payload }
) => {
  switch (type) {
    case ActionTypes.SET_PRODUCT_SEARCH:
      return { ...state, products: payload };
    default:
      return state;
  }
};
export const productsReducerWebsitesByImage = (
  state = intialState,
  { type, payload }
) => {
  switch (type) {
    case ActionTypes.SET_WEBSITES_BY_IMAGE:
      return { ...state, products: payload };
    default:
      return state;
  }
};

export const productsReducerWebsitesByImageCC = (
  state = intialState,
  { type, payload }
) => {
  switch (type) {
    case ActionTypes.SET_WEBSITES_BY_IMAGE_CC:
      return { ...state, products: payload };
    default:
      return state;
  }
};

export const productsReducerAliexpressImage = (
  state = intialState,
  { type, payload }
) => {
  switch (type) {
    case ActionTypes.SET_ALIEXPRESS_IMAGE:
      return { ...state, products: payload };
    default:
      return state;
  }
};
export const productsReducerAliexpressGoogle = (
  state = intialState,
  { type, payload }
) => {
  switch (type) {
    case ActionTypes.SET_GOOGLE:
      return { ...state, products: payload };
    default:
      return state;
  }
};
export const productsReducerAliexpressAmazon = (
  state = intialState,
  { type, payload }
) => {
  switch (type) {
    case ActionTypes.SET_AMAZON:
      return { ...state, products: payload };
    default:
      return state;
  }
};

export const productsReducerGoogleI = (
  state = intialState,
  { type, payload }
) => {
  switch (type) {
    case ActionTypes.SET_GOOGLE_I:
      return { ...state, products: payload };
    default:
      return state;
  }
};
export const productsReducerAmazonI = (
  state = intialState,
  { type, payload }
) => {
  switch (type) {
    case ActionTypes.SET_AMAZON_I:
      return { ...state, products: payload };
    default:
      return state;
  }
};
export const productsReducerAliexpressI = (
  state = intialState,
  { type, payload }
) => {
  switch (type) {
    case ActionTypes.SET_ALIEXPRESS_I:
      return { ...state, products: payload };
    default:
      return state;
  }
};

export const productsReducerGoogleICC = (
  state = intialState,
  { type, payload }
) => {
  switch (type) {
    case ActionTypes.SET_GOOGLE_I_CC:
      return { ...state, products: payload };
    default:
      return state;
  }
};
export const productsReducerAmazonICC = (
  state = intialState,
  { type, payload }
) => {
  switch (type) {
    case ActionTypes.SET_AMAZON_I_CC:
      return { ...state, products: payload };
    default:
      return state;
  }
};
export const productsReducerGoogleMax = (
  state = intialState,
  { type, payload }
) => {
  switch (type) {
    case ActionTypes.SET_GOOGLE_MAX:
      return { ...state, products: payload };
    default:
      return state;
  }
};
export const productsReducerAliexpressICC = (
  state = intialState,
  { type, payload }
) => {
  switch (type) {
    case ActionTypes.SET_ALIEXPRESS_I_CC:
      return { ...state, products: payload };
    default:
      return state;
  }
};

export const selectedProductsReducer = (state = {}, { type, payload }) => {
  console.log(type);
  switch (type) {
    case ActionTypes.SELECTED_PRODUCT:
      return { ...state, ...payload };
    case ActionTypes.REMOVE_SELECTED_PRODUCT:
      return {};
    default:
      return state;
  }
};
