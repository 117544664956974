import React from "react";
import { useEffect } from "react";

function ExtensionRedirect() {
  const getBrowserLink = (browser) => {
    let browserLink = "";
    if (browser == "Chrome") {
      browserLink =
        "https://chrome.google.com/webstore/detail/pricein-a-price-compariso/afipijkhaioeopfolgnagcicgpdlcink/related?hl=sv&authuser=0";
    } else if (browser == "Edge") {
      browserLink =
        "https://chrome.google.com/webstore/detail/pricein-a-price-compariso/afipijkhaioeopfolgnagcicgpdlcink/related?hl=sv&authuser=0";
    } else if (browser == "Safari") {
      browserLink =
        "https://apps.apple.com/se/app/pricein-same-product-cheaper/id6455427237?l=en-GB";
    } else {
      browserLink =
        "https://chrome.google.com/webstore/detail/pricein-a-price-compariso/afipijkhaioeopfolgnagcicgpdlcink/related?hl=sv&authuser=0";
    }

    return browserLink;
  };

  const getBrowser = () => {
    const userAgent = navigator.userAgent;
    console.log("userAgent" + userAgent);
    let browserName = "";
    if (userAgent.indexOf("Edg") > -1) {
      browserName = "Edge";
    } else if (userAgent.indexOf("Chrome") > -1) {
      browserName = "Chrome";
    } else if (userAgent.indexOf("Safari") > -1) {
      browserName = "Safari";
    } else {
      browserName = "Chrome";
    }

    return browserName;
  };
  const browser = getBrowser();
  const browserLink = getBrowserLink(browser);
  const footer = (document.querySelector("#root").style.display = "none");
  window.location.href = browserLink;

  return <div></div>;
}

export default ExtensionRedirect;
