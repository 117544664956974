import React, { useEffect, useState } from "react";
import "./PriceCompare.css";
import NavbarMobile from "./navbarpages/NavbarMobile";
import Header from "./Header";
import { Event } from "../additionalFunctions/GoogleAnalyticsFunctions";

function PriceCompare() {
  function moreOptions(ignoreData) {
    let allproducts = products.data.matches;
    const storesort = storeSort(allproducts, ignoreData);
    allproducts = storesort.newProducts;

    let renderList = allproducts.map((product) => {
      return createPCListing(product, products.data.original_item_price);
    });
    setProducts({ renderList, data: products.data });
  }
  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  function createPCListing(storeData, origionalPrice) {
    const handleImageError = (event) => {
      // Replace the source with the fallback image URL
      console.log("error image");
      event.target.src =
        "https://slimages.macysassets.com/is/image/MCY/products/3/optimized/18002263_fpx.tif?wid=1200&fmt=jpeg&qlt=100";
      event.target.remove();
    };
    return (
      <div className="pc-stores-listing">
        <div className="pc-store-data">
          <img
            src={
              "https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=" +
              storeData.productUrl +
              "&size=128"
            }
            className="pc-store-circle"
          ></img>
          <div className="pc-store-text">
            <div className="pc-store-name">
              <div className="p">{storeData.retailer_display_name}</div>
              {storeData.amount != 1 && (
                <div
                  onClick={() => {
                    moreOptions({
                      retailer: storeData.retailer,
                      skuCondition: storeData.skuCondition,
                    });
                  }}
                  className="pc-amountOnStore"
                >
                  {"x" + storeData.amount}
                </div>
              )}
            </div>
            <div className="pc-store-trusted">Trusted Seller</div>
          </div>
        </div>
        <div className="pc-listing-product-info">
          <img
            src={storeData.imageUrl}
            className="pc-listing-image"
            onError={handleImageError}
          ></img>
          <div className="pc-listing-price">
            {"$" + (storeData.salePrice / 1).toFixed(2)}
          </div>
          {storeData.salePrice < origionalPrice ? (
            <div className="pc-listing-save">
              {"Save " +
                ((1 - storeData.salePrice / origionalPrice) * 100).toFixed(0) +
                "%"}
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="pc-listing-condition">
          <p>{capitalizeFirstLetter(storeData.skuCondition)}</p>
        </div>
        <button
          onClick={() => {
            window.open(storeData.productUrl);
          }}
          className="pc-visit-site-button"
        >
          Visit Site
        </button>
      </div>
    );
  }

  const queryParams = new URLSearchParams(window.location.search);
  const productUrl = queryParams.get("url").split("").reverse().join("");
  const [originalImageUrl, setOriginalImageUrl] = useState(
    queryParams.get("img")
  );
  const originalTitle = queryParams.get("title");
  var data = "";
  const [products, setProducts] = useState(null);
  const productsCompared = async (url) => {
    console.log("productExists func");
    let data;
    const deviceId =
      "44671c5dd9bedb4a8a4e32e94dd599a369aca9559e39b8ef83b223d3c23";
    try {
      const response = await fetch(
        "https://matchos.price.com/apiv2/match_recommend?partner=5b960a4ade2962062eeb1f4a&url=" +
          url +
          "&source=extension"
      ); // Replace with your API endpoint
      if (!response.ok) {
        throw new Error("Request failed with status " + response.status);
      }

      data = await response.json(); // Parse response data as JSON
      console.log(data); // Do something with the parsed data
    } catch (error) {
      console.error("Error:", error.message);
    }

    return data;
  };
  function storeSort(allproducts, ignoreSort) {
    let newProducts = [];
    let storesAndConditionsList = [];
    for (let i = 0; i < allproducts.length; i++) {
      let prod = allproducts[i];
      let storeExist = false;
      for (let x = 0; x < storesAndConditionsList.length; x++) {
        const storeCond = storesAndConditionsList[x];
        if (
          prod.retailer == storeCond.retailer &&
          prod.skuCondition == storeCond.skuCondition
        ) {
          if (
            prod.retailer != ignoreSort.retailer ||
            prod.skuCondition != ignoreSort.skuCondition
          ) {
            storeExist = true;
            storesAndConditionsList[x].amount =
              storesAndConditionsList[x].amount + 1;
          }
        }
      }
      if (!storeExist) {
        newProducts.push(prod);
        storesAndConditionsList.push({
          retailer: prod.retailer,
          skuCondition: prod.skuCondition,
          amount: 1,
        });
      }
    }

    for (let i = 0; i < newProducts.length; i++) {
      let amount = 1;
      const prod = newProducts[i];

      for (let i = 0; i < storesAndConditionsList.length; i++) {
        if (
          prod.retailer == storesAndConditionsList[i].retailer &&
          prod.skuCondition == storesAndConditionsList[i].skuCondition
        ) {
          amount = storesAndConditionsList[i].amount;

          break;
        }
      }
      newProducts[i]["amount"] = amount;
    }
    console.log("newProducts", newProducts);
    return { newProducts: newProducts };
  }
  function getCheapestNewItem(items) {
    for (let i = 0; i < items.length; i++) {
      if (items[i].skuCondition == "new") return items[i];
    }
    return items[0];
  }
  useEffect(() => {
    productsCompared(productUrl)
      .then((results) => {
        let allproducts = results[0].matches;
        const ignoreSort = { retailer: "", skuCondition: "" };
        const storesort = storeSort(allproducts, ignoreSort);
        allproducts = storesort.newProducts;
        results[0]["recommendationNew"] = getCheapestNewItem(allproducts);
        console.log(results[0]);
        let renderList = allproducts.map((product) => {
          return createPCListing(product, results[0].original_item_price);
        });
        setProducts({ renderList, data: results[0] });
      })
      .catch((err) => {});
  }, []);
  const handleImageError = () => {
    setOriginalImageUrl(products.data.recommendationNew.imageUrl);
  };
  return (
    <>
      {products ? (
        <div className="pc-body">
          <Header mobileMode="true"></Header>
          <div className="pc-main-product-body">
            <div className="padding-div"></div>
            <div className="pc-image-div">
              <img
                className="pc-image"
                onError={handleImageError}
                src={originalImageUrl}
              ></img>
            </div>
            <div className="pc-product-info">
              <h1 className="pc-main-title">{originalTitle.slice(0, 80)}</h1>
              <div className="best-price-label">Best price</div>
              <div className="pc-main-price">
                <div className="pc-big-price">
                  {"$" + products.data.recommendationNew.price}
                </div>
                <div className="pc-price-stats">
                  <div className="pc-price-before">
                    {"$" + products.data.original_item_price}
                  </div>
                  <div className="pc-price-saved">
                    {"You save $" +
                      (
                        products.data.original_item_price -
                        products.data.recommendationNew.price
                      ).toFixed(2) +
                      " (" +
                      (
                        1 -
                        products.data.recommendationNew.price /
                          products.data.original_item_price
                      ).toFixed(2) *
                        100 +
                      "%)"}
                  </div>
                </div>
              </div>
              <button
                onClick={() => {
                  window.open(products.data.recommendationNew.productUrl);
                }}
                className="pc-main-buy-button"
              >
                {"Buy from " +
                  products.data.recommendationNew.retailer_display_name}
              </button>
            </div>
            <div className="padding-div"></div>
          </div>
          <div className="pc-all-stores">
            <div className="pc-stores-header">
              <div className="pc-amountofstores">Stores (8)</div>
              <div className="pc-lowest-price">Lowest price</div>
              <div className="pc-condition">Condition</div>
              <div className="stores-paddingdiv"></div>
            </div>
            {products.renderList}
          </div>
        </div>
      ) : (
        <>
          <div className="pc-body">
            <Header mobileMode="true"></Header>
            <div className="loading-heart-body">
              {false && (
                <>
                  <img src="/images/heart.webp" className="loading-heart"></img>
                  <div className="loading-heart-text">Loading Products...</div>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default PriceCompare;
