import { combineReducers } from "redux";
import {
  productsReducer,
  selectedProductsReducer,
  productsReducerAliexpress,
  productsReducerAliexpressGoogle,
  productsReducerAliexpressAmazon,
  productsReducerAliexpressImage,
  productsReducerWebsitesByImage,
  productsReducerWebsitesByImageCC,
  productsReducerAmazonI,
  productsReducerGoogleI,
  productsReducerAliexpressI,
  productsReducerAliexpressICC,
  productsReducerGoogleICC,
  productsReducerAmazonICC,
  productsReducerGoogleMax,
  productsReducerWalmart,
  productsReducerProductSearch,
} from "./productsReducer";
const reducers = combineReducers({
  allProducts: productsReducer,
  websiteByImageProducts: productsReducerWebsitesByImage,
  websiteByImageProductsCC: productsReducerWebsitesByImageCC,
  aliexpressProducts: productsReducerAliexpress,
  aliexpressProductsImage: productsReducerAliexpressImage,
  googleProducts: productsReducerAliexpressGoogle,
  amazonProducts: productsReducerAliexpressAmazon,
  walmartProducts: productsReducerWalmart,
  aliexpressProductsI: productsReducerAliexpressI,
  googleProductsI: productsReducerGoogleI,
  amazonProductsI: productsReducerAmazonI,
  aliexpressProductsICC: productsReducerAliexpressICC,
  googleProductsICC: productsReducerGoogleICC,
  amazonProductsICC: productsReducerAmazonICC,
  googleProductsMax: productsReducerGoogleMax,
  product: selectedProductsReducer,
  productSearch: productsReducerProductSearch,
});
export default reducers;
