import React, { Component } from "react";

class ImageWithFallback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      src: this.props.initialSrc,
      error: false,
    };
  }

  handleImageError = () => {
    console.log("IMage error");
    this.setState({ src: this.props.fallbackSrc, error: true });
  };

  render() {
    const { src, error } = this.state;
    const { alt } = this.props;

    return <img src={src} alt={alt} onError={this.handleImageError} />;
  }
}

export default ImageWithFallback;
