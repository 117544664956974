import React, { useEffect, useRef, useState } from "react";
import "./HomePage.css";
import { FaAngleDown, FaSearch } from "react-icons/fa";
import { BsFillCameraFill } from "react-icons/bs";
import $ from "jquery";
import exampleProductsData from "../additionalFunctions/displayProductsData.json";
import translations from "../additionalFunctions/translations.json";

import { MdCloudUpload } from "react-icons/md";
import { Link } from "react-router-dom";
import HomePageHeader from "./widgets/HomePageHeader";
import { isMobile, isIOS, isAndroid } from "react-device-detect";
import NavbarMobile from "./navbarpages/NavbarMobile";
import ReactFlagsSelect from "react-flags-select";
import {
  initGA,
  PageView,
  Event,
} from "../additionalFunctions/GoogleAnalyticsFunctions";
import ReactGA from "react-ga4";
import {
  ProductSearch,
  fetchSuggestions,
  getCountryNew,
  getCountry,
} from "../additionalFunctions/SearchRecommendations";
import countries from "i18n-iso-countries";
// Import the languages you want to use
import enLocale from "i18n-iso-countries/langs/en.json";
import itLocale from "i18n-iso-countries/langs/it.json";
import fs from "fs";

function HomePage() {
  const ComputerNewVersion = true;
  const MobileNewVersion = true;
  console.log(exampleProductsData);
  function isMobileDevice() {
    return /Mobi|Android/i.test(navigator.userAgent);
  }
  console.log("Is mobile:", isMobileDevice());
  function Utils() {}
  const [image, setImage] = useState("");
  const imageFile = useRef();
  const [inputText, setInputText] = useState("");

  const [inputSuggestions, setInputSuggestions] = useState();
  const [videOn, setVideoOn] = useState(true);

  const [showPopup, setShowPopup] = useState(false);
  let countryCode = useRef("us");
  function openBrowserLink(category, link, browser) {
    Event(category, "BrowserLink", browser, browser);

    console.log("Send Click Event");
    // window.open(link, "_blank");
  }

  const [nProductsShow, setNProductsShow] = useState(6);
  const togglePopup = () => {
    setShowPopup(!showPopup);
  };
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const videoRef = useRef(null);
  const videoRef2 = useRef(null);
  const videoRef3 = useRef(null);
  const [hasScrolled, setHasScrolled] = useState(false);

  const togglePlay = () => {
    console.log(videOn);

    if (videOn) {
      videoRef.current.pause();
      videoRef2.current.pause();
      videoRef3.current.pause();
    } else {
      videoRef.current.play();
      videoRef2.current.play();
      videoRef3.current.play();
    }
    setVideoOn(!videOn);
  };

  function findProduct(title) {
    /*
    const fileInput = document.getElementById("image-input");
    const blobURL =
      "blob:http://localhost:3000/1bde1784-dfa8-4c93-be31-c24eb36c7ddf";

    fetch(blobURL)
      .then((response) => response.blob())
      .then((blob) => {
        // Create a Blob object with the binary data and image MIME type
        const imageBlob = new Blob([blob], { type: "image/png" });

        // Create a URL for the Blob
        ProductSearch(imageBlob);

        // Now, 'imageURL' can be used to display or download the image.
      })
      .catch((error) => {
        console.error("Error fetching blob data:", error);
      });

  */

    console.log(countryCode.current);

    var img = image;
    console.log(img);

    if (title == "" && image != "") {
      window.open(
        "https://pricein.ai/product/finder/?title=" +
          title +
          "&img=" +
          img +
          "&imgonly=true" +
          "&cc=" +
          selectedCountry,
        "_blank"
      );
    } else if (title != "" && image != "") {
      window.open(
        "https://pricein.ai/product/finder/?title=" +
          title +
          "&img=" +
          img +
          "&cc=" +
          selectedCountry,
        "_blank"
      );
    } else if (title != "" && image == "") {
      /*
      console.log("create popup");
      togglePopup();*/

      window.open(
        "https://pricein.ai/product/finder/?title=" +
          title +
          "&img=null" +
          "&cc=" +
          selectedCountry,
        "_blank"
      );
    }
  }

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      console.log(entry);
      if (entry.isIntersecting) {
        entry.target.classList.add("show");
      }
    });
  });
  const mobileWidth = 1000;
  const [mobileMode, setMobileMode] = useState(window.innerWidth < mobileWidth);
  let haveSetMobileMode = window.innerWidth < mobileWidth;
  const handleResize = () => {
    if (window.innerWidth < mobileWidth && !haveSetMobileMode) {
      setMobileMode(true);
      haveSetMobileMode = true;
      console.log("Setting mobile mode to", true);
    } else if (window.innerWidth > mobileWidth && haveSetMobileMode) {
      setMobileMode(false);
      haveSetMobileMode = false;
      console.log("Setting mobile mode to", false);
    }
  };

  const getBrowserLink = (browser) => {
    let browserLink = "";
    if (browser == "Chrome") {
      browserLink =
        "https://chrome.google.com/webstore/detail/pricein-a-price-compariso/afipijkhaioeopfolgnagcicgpdlcink/related?hl=sv&authuser=0";
    } else if (browser == "Edge") {
      browserLink =
        "https://chrome.google.com/webstore/detail/pricein-a-price-compariso/afipijkhaioeopfolgnagcicgpdlcink/related?hl=sv&authuser=0";
    } else if (browser == "Safari") {
      browserLink =
        "https://apps.apple.com/se/app/pricein-same-product-cheaper/id6455427237?l=en-GB";
    } else {
      browserLink =
        "https://chrome.google.com/webstore/detail/pricein-a-price-compariso/afipijkhaioeopfolgnagcicgpdlcink/related?hl=sv&authuser=0";
    }

    return browserLink;
  };

  const getBrowser = () => {
    const userAgent = navigator.userAgent;
    console.log("userAgent" + userAgent);
    let browserName = "";
    if (userAgent.indexOf("Edg") > -1) {
      browserName = "Edge";
    } else if (userAgent.indexOf("Chrome") > -1) {
      browserName = "Chrome";
    } else if (userAgent.indexOf("Safari") > -1) {
      browserName = "Safari";
    } else {
      browserName = "Chrome";
    }

    return browserName;
  };

  const browser = getBrowser();
  const browserLink = getBrowserLink(browser);

  useEffect(() => {
    const handleScroll = () => {
      if (!hasScrolled) {
        setHasScrolled(true);
        videoRef.current.play();
        window.removeEventListener("scroll", handleScroll);
      }
    };
    const hiddenElements = document.querySelectorAll(".hidden");
    hiddenElements.forEach((el) => {
      observer.observe(el);
    });
    window.addEventListener("resize", handleResize);
    if (mobileMode) {
      console.log("scrolled");
      // window.addEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("resize", handleResize);
      // window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const userLanguage = navigator.language.split("-")[0];
  console.log(userLanguage);

  function onChangeHandler(text) {
    console.log("fetching suggestion");
    setInputText(text);

    fetch(
      "https://server-dot-tryshopextension.uc.r.appspot.com/suggestions?text=" +
        text +
        "&l=en"
    )
      .then((reponse) => reponse.json())
      .then((result) => {
        console.log(result);
        setInputSuggestions(result);
      })
      .catch((err) => {});
  }

  const selectCountryHandler = (value) => setSelectedCountry(value);

  // Have to register the languages you want to use
  countries.registerLocale(enLocale);
  countries.registerLocale(itLocale);

  // Returns an object not a list
  const countryObj = countries.getNames("en", { select: "official" });

  const countryArr = Object.entries(countryObj).map(([key, value]) => {
    return {
      label: value,
      value: key,
    };
  });

  function getState() {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (timezone === "" || !timezone) {
      return null;
    }

    const state = timezone.split("/")[1].replace("_", " ");

    return state;
  }

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      // Call your function here when Enter key is pressed
      // For example, you can call a submit function
      findProduct(inputText);
    }
  };

  function getPlaceHolderText() {
    let text = "What are you looking for?";
    const translationsPlaceholder = translations.placeholderText;
    for (let index = 0; index < translationsPlaceholder.length; index++) {
      const element = translationsPlaceholder[index];

      if (element.country_code == selectedCountry.toLowerCase()) {
        text = element.translation;
        console.log(element.translation);
        break;
      }
    }
    return text;
  }

  const usersCountry = getCountryNew();
  console.log(usersCountry["_country"]);
  const [selectedCountry, setSelectedCountry] = useState(
    usersCountry["_country"]
  );
  let exampleProducts = [];
  if (exampleProductsData[selectedCountry.toLowerCase()])
    exampleProducts =
      exampleProductsData[selectedCountry.toLowerCase()]["results"];
  else exampleProducts = exampleProductsData["us"]["results"];
  const placeholder = getPlaceHolderText();

  return (
    <>
      {" "}
      {!isMobile || !mobileMode ? (
        <>
          {!ComputerNewVersion ? (
            <div className="home-page-body">
              <HomePageHeader></HomePageHeader>
              {showPopup && (
                <div className="no-image-popup">
                  <div class="error-content">
                    <h6>Add image</h6>
                    <p>
                      We recommend that you add an image for more specifc
                      results
                    </p>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <button onClick={togglePopup} class="close-button">
                        Close
                      </button>
                      <button
                        onClick={() => {
                          togglePopup();
                          const title =
                            document.getElementsByClassName(
                              "input-field-url"
                            )[0].value;
                          window.open(
                            "https://pricein.ai/product/finder/?title=" +
                              title +
                              "&img=null" +
                              "&cc=" +
                              countryCode.current,
                            "_blank"
                          );
                        }}
                        class="close-button search"
                      >
                        Search without Image
                      </button>
                    </div>
                  </div>
                </div>
              )}
              <div className="info-banners">
                <div className="banner amount-of-products">
                  <p className="text-one-b">COMPARE OVER</p>
                  <p className="text-two-b">+1,000,000</p>
                  <p className="text-one-b">STORES</p>
                </div>
                <div className="banner second-informaiton">
                  <p className="text-one-b">
                    Save an Average of 20% on Every Purchase
                  </p>
                  <a href={browserLink} target="_blank" className="text-two-b">
                    Download Now.
                  </a>
                </div>
              </div>
              <section className="content-body">
                <div className="hero-section-background">
                  <img
                    src="/images/bluebackground.webp"
                    alt="blue polygon background"
                  ></img>
                </div>
                <div className="content-body-left">
                  <h2 className="main-text">Same product lower price</h2>
                  <p className="second-text">
                    Pricein's compares products online and identifies the most
                    similar ones to the product you want to buy. Pricein
                    searches the entire web so you don't have to.
                  </p>
                  <div className="input-field-div">
                    <div className="input-box">
                      <div className="input-field-box">
                        <div className="input-write-cointainer">
                          <FaSearch
                            className="search-icon-homepage"
                            color="#375BD2"
                          />
                          <input
                            className="input-field-url"
                            type="text"
                            placeholder={"Search for a Product..."}
                            onChange={(e) => {
                              onChangeHandler(e.target.value);
                            }}
                            value={inputText}
                          ></input>
                        </div>
                        <div className="field-info-inputs">
                          <div className="search-buttons-divider-line"></div>
                          {!image && (
                            <form
                              action=""
                              className="img-upload-button"
                              onClick={() =>
                                document.querySelector("#image-input").click()
                              }
                            >
                              <input
                                type="file"
                                accept="image/*"
                                id="image-input"
                                onChange={({ target: { files } }) => {
                                  if (files) {
                                    console.log(URL.createObjectURL(files[0]));
                                    setImage(URL.createObjectURL(files[0]));
                                    imageFile.current = files[0];
                                  }
                                }}
                                hidden
                              />

                              <img
                                className="photo-icon"
                                src="https://cdn-icons-png.flaticon.com/128/10054/10054290.png"
                              ></img>
                              <p>Add Image</p>
                            </form>
                          )}
                          {image && (
                            <div className="img-icon homepage">
                              <img
                                src={image}
                                className="product-image"
                                onClick={() =>
                                  document.querySelector("#image-input").click()
                                }
                              />

                              <img
                                src={image}
                                className="image-on-hover-icon"
                              ></img>
                              <input
                                type="file"
                                accept="image/*"
                                id="image-input"
                                onChange={({ target: { files } }) => {
                                  if (files) {
                                    console.log(URL.createObjectURL(files[0]));
                                    setImage(URL.createObjectURL(files[0]));
                                    imageFile.current = files[0];
                                  }
                                }}
                                hidden
                              />
                              <button
                                onClick={() => {
                                  setImage(null);
                                }}
                              >
                                <img src="images/exit-button-image.png" />
                              </button>
                            </div>
                          )}
                          <div className="search-buttons-divider-line"></div>
                          <div className="country-input">
                            {" "}
                            <div
                              style={{ display: "none" }}
                              className="country-input-text"
                            >
                              United States
                            </div>
                            <img
                              style={{ display: "none" }}
                              className="country-select-icon"
                              src="https://cdn-icons-png.flaticon.com/128/271/271210.png"
                            ></img>
                            <select
                              value={selectedCountry}
                              className="select-country-div"
                              onChange={(e) =>
                                selectCountryHandler(e.target.value)
                              }
                            >
                              <option
                                key={usersCountry["_country"]}
                                value={usersCountry["_country"]}
                              >
                                {usersCountry["country"]}
                              </option>
                              {!!countryArr?.length &&
                                countryArr.map(({ label, value }) => (
                                  <option key={value} value={value}>
                                    {label}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button
                      onClick={findProduct}
                      // onClick={fetchProductData}

                      className="input-button-find"
                    >
                      Find Now
                    </button>
                  </div>
                  <div className="suggestions-container">
                    {inputText &&
                      inputSuggestions &&
                      inputSuggestions.map((suggestion, i) => {
                        return (
                          <>
                            <div
                              onClick={() => {
                                setInputText(suggestion);
                                setInputSuggestions([]);
                              }}
                              className="input-suggestion"
                              key={i}
                            >
                              {suggestion}
                            </div>
                          </>
                        );
                      })}
                  </div>
                </div>
                <div className="content-body-right">
                  <div className="video-body">
                    <video
                      onMouseEnter={() => {
                        videoRef2.current.play();
                        setVideoOn(true);
                      }}
                      ref={videoRef2}
                      class="tutorial-video"
                      poster="/images/posterimage.webp"
                      muted
                      playsInline
                      loop
                    >
                      <source
                        src="/images/extensiontutorial.mp4"
                        type="video/mp4"
                      />
                    </video>
                  </div>
                  <span class="video-on-off" onClick={togglePlay}>
                    {videOn ? (
                      <>
                        <svg
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          fill="white"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                        >
                          <g clip-path="url(#clip0_2_213)">
                            <circle
                              cx="16"
                              cy="16"
                              r="15"
                              stroke="#565B6C"
                              stroke-width="2"
                            ></circle>
                            <rect
                              x="20"
                              y="10"
                              width="12"
                              height="2"
                              rx="1"
                              transform="rotate(90 20 10)"
                              fill="#565B6C"
                            ></rect>
                            <rect
                              x="14"
                              y="10"
                              width="12"
                              height="2"
                              rx="1"
                              transform="rotate(90 14 10)"
                              fill="#565B6C"
                            ></rect>
                          </g>
                          <defs>
                            <clipPath id="clip0_2_213">
                              <rect width="32" height="32" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </>
                    ) : (
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="white"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                      >
                        <g clip-path="url(87489656-c327-45fb-9b94-d0dd4ddf69d1)">
                          <circle
                            cx="16"
                            cy="16"
                            r="15"
                            stroke="#565B6C"
                            stroke-width="2"
                          ></circle>
                          <path
                            d="M21.7003 15.1601C22.31 15.5541 22.31 16.4459 21.7003 16.8399L14.234 21.6648C13.5686 22.0948 12.6913 21.6172 12.6913 20.825L12.6913 11.175C12.6913 10.3828 13.5686 9.90516 14.234 10.3352L21.7003 15.1601Z"
                            fill="#565B6C"
                          ></path>
                        </g>
                        <defs>
                          <clipPath id="87489656-c327-45fb-9b94-d0dd4ddf69d1">
                            <rect width="32" height="32" fill="white"></rect>
                          </clipPath>
                        </defs>
                      </svg>
                    )}
                  </span>
                  <a
                    href={browserLink}
                    target="_blank"
                    onClick={() => {
                      openBrowserLink(
                        "Get for under video button",
                        browserLink,
                        browser
                      );
                    }}
                    className="downolad-extension-button"
                  >
                    {"Get for " + browser}
                  </a>
                </div>
              </section>
              <div className="company-logos">
                <div className="grey-promotion-texts">Average save: 20%</div>
                <div className="grey-promotion-texts">
                  Price for Usage: FREE
                </div>
                <div className="grey-promotion-texts">Technology: AI</div>
                <div className="grey-promotion-texts">Countries: All</div>
              </div>
              <div className="content-texts-section">
                <div className="content-texts-part">
                  <div className="show-search-by-image">
                    <div className="searchbyimage-video-body hidden">
                      <video
                        ref={videoRef3}
                        class="tutorial-video-searchby"
                        autoPlay
                        muted
                        playsInline
                        loop
                      >
                        <source
                          src="/images/FindByImage.mp4"
                          type="video/mp4"
                        />
                      </video>
                      <span
                        class="video-on-off-searchbyimage"
                        onClick={togglePlay}
                      >
                        {videOn && (
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="white"
                            xmlns="http://www.w3.org/2000/svg"
                            aria-hidden="true"
                          >
                            <g clip-path="url(#clip0_2_213)">
                              <circle
                                cx="16"
                                cy="16"
                                r="15"
                                stroke="#565B6C"
                                stroke-width="2"
                              ></circle>
                              <rect
                                x="20"
                                y="10"
                                width="12"
                                height="2"
                                rx="1"
                                transform="rotate(90 20 10)"
                                fill="#565B6C"
                              ></rect>
                              <rect
                                x="14"
                                y="10"
                                width="12"
                                height="2"
                                rx="1"
                                transform="rotate(90 14 10)"
                                fill="#565B6C"
                              ></rect>
                            </g>
                            <defs>
                              <clipPath id="clip0_2_213">
                                <rect
                                  width="32"
                                  height="32"
                                  fill="white"
                                ></rect>
                              </clipPath>
                            </defs>
                          </svg>
                        )}
                        {!videOn && (
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="white"
                            xmlns="http://www.w3.org/2000/svg"
                            aria-hidden="true"
                          >
                            <g clip-path="url(87489656-c327-45fb-9b94-d0dd4ddf69d1)">
                              <circle
                                cx="16"
                                cy="16"
                                r="15"
                                stroke="#565B6C"
                                stroke-width="2"
                              ></circle>
                              <path
                                d="M21.7003 15.1601C22.31 15.5541 22.31 16.4459 21.7003 16.8399L14.234 21.6648C13.5686 22.0948 12.6913 21.6172 12.6913 20.825L12.6913 11.175C12.6913 10.3828 13.5686 9.90516 14.234 10.3352L21.7003 15.1601Z"
                                fill="#565B6C"
                              ></path>
                            </g>
                            <defs>
                              <clipPath id="87489656-c327-45fb-9b94-d0dd4ddf69d1">
                                <rect
                                  width="32"
                                  height="32"
                                  fill="white"
                                ></rect>
                              </clipPath>
                            </defs>
                          </svg>
                        )}
                      </span>
                    </div>
                    <div className="left-serchbyimage-content hidden">
                      <h3 className="serchbyimage-content-title">
                        You can find products using only an image
                      </h3>
                      <p className="serchbyimage-content-text">
                        Whenever an image of a desired product catches your eye,
                        simply right-click and select "Find Product." Pricein's
                        cutting-edge AI technology will then locate similar
                        items for you.
                      </p>
                    </div>
                  </div>
                  <h3 className="content-subject-title hidden">
                    Discover the Same Product for Less
                  </h3>
                  <div className="hompage-content-boxes">
                    <div className="content-box hidden">
                      <div className="content-box-icon">
                        <img
                          className="extension-icon-image"
                          src="/images/extension.webp"
                          alt="extension icon"
                        ></img>
                      </div>
                      <div className="box-subtitle">Browser Extension</div>
                      <div className="content-box-content">
                        The Pricein Browser Extension is user-friendly - attach
                        it to your browser. When you visit a product page a
                        small icon will appear. With two clicks you can then
                        find the same product cheaper.
                      </div>
                    </div>
                    <div className="content-box hidden">
                      <div className="content-box-icon hidden">
                        <img
                          src="/images/intelligence.webp"
                          alt="intelligence icon"
                        ></img>
                      </div>
                      <div className="box-subtitle">Ai search engine</div>
                      <div className="content-box-content">
                        At Pricein, we employ state-of-the-art AI technology to
                        effortlessly guide you to the perfect product on any
                        website. By analyzing both product images and titles,
                        our technology identifies items that match in appearance
                        and function.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="content-texts-part">
                  <h3 className="content-subject-title hidden">
                    Benefits of Using Pricein
                  </h3>
                  <div className="hompage-content-boxes">
                    <div className="content-box hidden">
                      <div className="content-box-icon">
                        <img src="/images/coupon.webp" alt="coupon icon"></img>
                      </div>
                      <div className="box-subtitle">
                        Save 20% on your purchase
                      </div>
                      <div className="content-box-content">
                        In our testing, we consistently saved an average of 20%
                        or more on our purchases by utilizing our Browser
                        extension to pinpoint the same product at a reduced
                        price on alternate sites.
                      </div>
                    </div>
                    <div className="content-box hidden">
                      <div className="content-box-icon">
                        <img
                          src="/images/shopify.webp"
                          alt="shopify icon"
                        ></img>
                      </div>
                      <div className="box-subtitle">
                        Fight Copycats and Dropshippers
                      </div>
                      <div className="content-box-content">
                        If the product you desire is at risk of being a copycat
                        or dropshipping item, chances are it's available across
                        numerous online stores. Our tool helps you locate
                        budget-friendly options, including
                        direct-from-manufacturer choices.
                      </div>
                    </div>
                  </div>
                  <div className="last-download-section hidden">
                    <div className="second-video-body">
                      <video
                        ref={videoRef}
                        class="tutorial-video-second"
                        poster="/images/posterimage.webp"
                        autoPlay
                        muted
                        playsInline
                        loop
                      >
                        <source
                          src="/images/extensiontutorial.mp4"
                          type="video/mp4"
                        />
                      </video>
                      <span class="video-on-off-last" onClick={togglePlay}>
                        {videOn && (
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="white"
                            xmlns="http://www.w3.org/2000/svg"
                            aria-hidden="true"
                          >
                            <g clip-path="url(#clip0_2_213)">
                              <circle
                                cx="16"
                                cy="16"
                                r="15"
                                stroke="#565B6C"
                                stroke-width="2"
                              ></circle>
                              <rect
                                x="20"
                                y="10"
                                width="12"
                                height="2"
                                rx="1"
                                transform="rotate(90 20 10)"
                                fill="#565B6C"
                              ></rect>
                              <rect
                                x="14"
                                y="10"
                                width="12"
                                height="2"
                                rx="1"
                                transform="rotate(90 14 10)"
                                fill="#565B6C"
                              ></rect>
                            </g>
                            <defs>
                              <clipPath id="clip0_2_213">
                                <rect
                                  width="32"
                                  height="32"
                                  fill="white"
                                ></rect>
                              </clipPath>
                            </defs>
                          </svg>
                        )}
                        {!videOn && (
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="white"
                            xmlns="http://www.w3.org/2000/svg"
                            aria-hidden="true"
                          >
                            <g clip-path="url(87489656-c327-45fb-9b94-d0dd4ddf69d1)">
                              <circle
                                cx="16"
                                cy="16"
                                r="15"
                                stroke="#565B6C"
                                stroke-width="2"
                              ></circle>
                              <path
                                d="M21.7003 15.1601C22.31 15.5541 22.31 16.4459 21.7003 16.8399L14.234 21.6648C13.5686 22.0948 12.6913 21.6172 12.6913 20.825L12.6913 11.175C12.6913 10.3828 13.5686 9.90516 14.234 10.3352L21.7003 15.1601Z"
                                fill="#565B6C"
                              ></path>
                            </g>
                            <defs>
                              <clipPath id="87489656-c327-45fb-9b94-d0dd4ddf69d1">
                                <rect
                                  width="32"
                                  height="32"
                                  fill="white"
                                ></rect>
                              </clipPath>
                            </defs>
                          </svg>
                        )}
                      </span>
                    </div>
                    <div className="two-buttons-bottom">
                      <Link to="/about">
                        <button className="downolad-extension-button">
                          About Us
                        </button>
                      </Link>
                      <a
                        href={browserLink}
                        target="_blank"
                        style={{ height: "44px" }}
                        onClick={() => {
                          openBrowserLink(
                            "Add Extension button on bottom of page",
                            browserLink,
                            browser
                          );
                        }}
                        className="filled-button"
                      >
                        Add Extension
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="home-page-body">
              <div className="new-header">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <NavbarMobile></NavbarMobile>
                  <h2 className="new-logo">Pricein</h2>
                </div>
                <div style={{ display: "flex" }}>
                  <div className="new-search-field">
                    <div
                      className="new-input-search-box"
                      style={
                        inputText.length != 0
                          ? {
                              borderRadius: "24px 24px 0 0",
                              borderBottom: "none",
                            }
                          : {}
                      }
                    >
                      <img
                        className="new-search-icon"
                        src="/images/loupe (1).png"
                      ></img>
                      <input
                        className="new-input-field-url"
                        type="text"
                        placeholder={placeholder}
                        onKeyDown={handleKeyPress}
                        onChange={(e) => {
                          onChangeHandler(e.target.value);
                        }}
                        value={inputText}
                      ></input>
                      {!image && (
                        <form
                          action=""
                          className="img-upload-button"
                          onClick={() =>
                            document.querySelector("#image-input").click()
                          }
                        >
                          <input
                            type="file"
                            accept="image/*"
                            id="image-input"
                            onChange={({ target: { files } }) => {
                              if (files) {
                                console.log(URL.createObjectURL(files[0]));
                                setImage(URL.createObjectURL(files[0]));
                                imageFile.current = files[0];
                              }
                            }}
                            hidden
                          />

                          <img
                            className="photo-icon"
                            src="/images/cameraicon.png"
                          ></img>
                        </form>
                      )}
                      {image && (
                        <div className="img-icon homepage">
                          <img
                            src={image}
                            className="product-image"
                            onClick={() =>
                              document.querySelector("#image-input").click()
                            }
                          />

                          <img
                            src={image}
                            className="image-on-hover-icon"
                          ></img>
                          <input
                            type="file"
                            accept="image/*"
                            id="image-input"
                            onChange={({ target: { files } }) => {
                              if (files) {
                                console.log(URL.createObjectURL(files[0]));
                                setImage(URL.createObjectURL(files[0]));
                                imageFile.current = files[0];
                              }
                            }}
                            hidden
                          />
                          <button
                            onClick={() => {
                              setImage(null);
                            }}
                          >
                            <img src="images/exit-button-image.png" />
                          </button>
                        </div>
                      )}
                    </div>
                    {inputText.length != 0 && inputSuggestions ? (
                      <div className="new-suggestions">
                        {inputSuggestions.slice(0, 6).map((suggestion, i) => {
                          return (
                            <>
                              <div
                                onClick={() => {
                                  findProduct(suggestion);
                                  setInputText("");
                                }}
                                className="input-suggestion"
                                key={i}
                              >
                                {suggestion}
                              </div>
                            </>
                          );
                        })}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <ReactFlagsSelect
                    className="react-select-flags-homepage"
                    style={{ border: "none" }}
                    selected={selectedCountry}
                    onSelect={(code) => setSelectedCountry(code)}
                    searchable
                    showSelectedLabel={false}
                    showOptionLabel={true}
                    selectedSize={20}
                  ></ReactFlagsSelect>
                </div>
                <a
                  href={browserLink}
                  target="_blank"
                  onClick={openBrowserLink(
                    "Add Extension button header",
                    browserLink,
                    browser
                  )}
                  className="new-download-extension"
                >
                  Download Chrome Extension
                </a>
              </div>
              <div className="new-herosection">
                <div style={{ marginTop: "40px" }}>
                  <h2 className="herosection-text">Find the best deal,</h2>
                  <h2 className="herosection-text">
                    It's Google but for products
                  </h2>
                </div>
                <div>
                  <img
                    className="computer-image"
                    src="/images/ComputerExtensionImage.png"
                  ></img>
                </div>
              </div>
              <div className="products-suggestions">
                <div className="products-suggestion-title">Ex: Iphone 15</div>
                <div className="products-suggestion-products">
                  {exampleProducts.slice(0, nProductsShow).map((item) => (
                    <a
                      className="suggestions-products"
                      href={item.link}
                      target="_blank"
                    >
                      <div className="suggestion-image-frame">
                        <img className="suggestion-image" src={item.img}></img>
                      </div>
                      <div className="suggestion-product-price">
                        {item.price}
                      </div>
                      <div className="suggestion-product-title">
                        {item.title.slice(0, 50)}
                      </div>
                    </a>
                  ))}
                </div>
                <button
                  onClick={() => {
                    setNProductsShow(nProductsShow + 6);
                  }}
                  className="view-more-home-page"
                >
                  View more
                </button>
              </div>
              <div className="content-texts-section">
                <div className="content-texts-part">
                  <h3 className="content-subject-title hidden">
                    Compare products from 1,000,000+ stores
                  </h3>
                  <div className="hompage-content-boxes">
                    <div className="content-box hidden">
                      <div className="content-box-icon">
                        <img src="/images/coupon.webp" alt="coupon icon"></img>
                      </div>
                      <div className="box-subtitle">
                        Save 20% on your purchase
                      </div>
                      <div className="content-box-content">
                        In our testing, we consistently saved an average of 20%
                        or more on our purchases by utilizing our Browser
                        extension to pinpoint the same product at a reduced
                        price on alternate sites.
                      </div>
                    </div>

                    <div className="content-box hidden">
                      <div className="content-box-icon hidden">
                        <img
                          src="/images/intelligence.webp"
                          alt="intelligence icon"
                        ></img>
                      </div>
                      <div className="box-subtitle">
                        It's Google but for products
                      </div>
                      <div className="content-box-content">
                        At Pricein, we use a search engine instead of a fixed
                        directory of products. Therefore, we have access to all
                        the internet's products instead of just a small
                        minority. We compare products from over 1,000,000
                        stores.
                      </div>
                    </div>
                  </div>
                  <div className="show-search-by-image">
                    <div className="searchbyimage-video-body hidden">
                      <video
                        ref={videoRef3}
                        class="tutorial-video-searchby"
                        autoPlay
                        muted
                        playsInline
                        loop
                      >
                        <source
                          src="/images/FindByImage.mp4"
                          type="video/mp4"
                        />
                      </video>
                      <span
                        class="video-on-off-searchbyimage"
                        onClick={togglePlay}
                      >
                        {videOn && (
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="white"
                            xmlns="http://www.w3.org/2000/svg"
                            aria-hidden="true"
                          >
                            <g clip-path="url(#clip0_2_213)">
                              <circle
                                cx="16"
                                cy="16"
                                r="15"
                                stroke="#565B6C"
                                stroke-width="2"
                              ></circle>
                              <rect
                                x="20"
                                y="10"
                                width="12"
                                height="2"
                                rx="1"
                                transform="rotate(90 20 10)"
                                fill="#565B6C"
                              ></rect>
                              <rect
                                x="14"
                                y="10"
                                width="12"
                                height="2"
                                rx="1"
                                transform="rotate(90 14 10)"
                                fill="#565B6C"
                              ></rect>
                            </g>
                            <defs>
                              <clipPath id="clip0_2_213">
                                <rect
                                  width="32"
                                  height="32"
                                  fill="white"
                                ></rect>
                              </clipPath>
                            </defs>
                          </svg>
                        )}
                        {!videOn && (
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="white"
                            xmlns="http://www.w3.org/2000/svg"
                            aria-hidden="true"
                          >
                            <g clip-path="url(87489656-c327-45fb-9b94-d0dd4ddf69d1)">
                              <circle
                                cx="16"
                                cy="16"
                                r="15"
                                stroke="#565B6C"
                                stroke-width="2"
                              ></circle>
                              <path
                                d="M21.7003 15.1601C22.31 15.5541 22.31 16.4459 21.7003 16.8399L14.234 21.6648C13.5686 22.0948 12.6913 21.6172 12.6913 20.825L12.6913 11.175C12.6913 10.3828 13.5686 9.90516 14.234 10.3352L21.7003 15.1601Z"
                                fill="#565B6C"
                              ></path>
                            </g>
                            <defs>
                              <clipPath id="87489656-c327-45fb-9b94-d0dd4ddf69d1">
                                <rect
                                  width="32"
                                  height="32"
                                  fill="white"
                                ></rect>
                              </clipPath>
                            </defs>
                          </svg>
                        )}
                      </span>
                    </div>
                    <div className="left-serchbyimage-content hidden">
                      <h3 className="serchbyimage-content-title">
                        You can find products using only an image
                      </h3>
                      <p className="serchbyimage-content-text">
                        Whenever an image of a desired product catches your eye,
                        simply right-click and select "Find Product." Pricein's
                        cutting-edge AI technology will then locate similar
                        items for you.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="content-texts-part">
                  <h3 className="content-subject-title hidden">
                    Benefits of Using Pricein
                  </h3>
                  <div className="hompage-content-boxes">
                    <div className="content-box hidden">
                      <div className="content-box-icon">
                        <img
                          className="extension-icon-image"
                          src="/images/extension.webp"
                          alt="extension icon"
                        ></img>
                      </div>
                      <div className="box-subtitle">Browser Extension</div>
                      <div className="content-box-content">
                        The Pricein Browser Extension is user-friendly - attach
                        it to your browser. When you visit a product page a
                        small icon will appear. With two clicks you can then
                        find the same product cheaper.
                      </div>
                    </div>
                    <div className="content-box hidden">
                      <div className="content-box-icon">
                        <img
                          src="/images/shopify.webp"
                          alt="shopify icon"
                        ></img>
                      </div>
                      <div className="box-subtitle">
                        Fight Copycats and Dropshippers
                      </div>
                      <div className="content-box-content">
                        If the product you desire is at risk of being a copycat
                        or dropshipping item, chances are it's available across
                        numerous online stores. Our tool helps you locate
                        budget-friendly options, including
                        direct-from-manufacturer choices.
                      </div>
                    </div>
                  </div>
                  <div className="last-download-section hidden">
                    <div className="second-video-body">
                      <video
                        ref={videoRef}
                        class="tutorial-video-second"
                        poster="/images/posterimage.webp"
                        autoPlay
                        muted
                        playsInline
                        loop
                      >
                        <source
                          src="/images/extensiontutorial.mp4"
                          type="video/mp4"
                        />
                      </video>
                      <span class="video-on-off-last" onClick={togglePlay}>
                        {videOn && (
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="white"
                            xmlns="http://www.w3.org/2000/svg"
                            aria-hidden="true"
                          >
                            <g clip-path="url(#clip0_2_213)">
                              <circle
                                cx="16"
                                cy="16"
                                r="15"
                                stroke="#565B6C"
                                stroke-width="2"
                              ></circle>
                              <rect
                                x="20"
                                y="10"
                                width="12"
                                height="2"
                                rx="1"
                                transform="rotate(90 20 10)"
                                fill="#565B6C"
                              ></rect>
                              <rect
                                x="14"
                                y="10"
                                width="12"
                                height="2"
                                rx="1"
                                transform="rotate(90 14 10)"
                                fill="#565B6C"
                              ></rect>
                            </g>
                            <defs>
                              <clipPath id="clip0_2_213">
                                <rect
                                  width="32"
                                  height="32"
                                  fill="white"
                                ></rect>
                              </clipPath>
                            </defs>
                          </svg>
                        )}
                        {!videOn && (
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="white"
                            xmlns="http://www.w3.org/2000/svg"
                            aria-hidden="true"
                          >
                            <g clip-path="url(87489656-c327-45fb-9b94-d0dd4ddf69d1)">
                              <circle
                                cx="16"
                                cy="16"
                                r="15"
                                stroke="#565B6C"
                                stroke-width="2"
                              ></circle>
                              <path
                                d="M21.7003 15.1601C22.31 15.5541 22.31 16.4459 21.7003 16.8399L14.234 21.6648C13.5686 22.0948 12.6913 21.6172 12.6913 20.825L12.6913 11.175C12.6913 10.3828 13.5686 9.90516 14.234 10.3352L21.7003 15.1601Z"
                                fill="#565B6C"
                              ></path>
                            </g>
                            <defs>
                              <clipPath id="87489656-c327-45fb-9b94-d0dd4ddf69d1">
                                <rect
                                  width="32"
                                  height="32"
                                  fill="white"
                                ></rect>
                              </clipPath>
                            </defs>
                          </svg>
                        )}
                      </span>
                    </div>
                    <div className="two-buttons-bottom">
                      <Link to="/about">
                        <button className="downolad-extension-button">
                          About Us
                        </button>
                      </Link>
                      <a
                        href={browserLink}
                        target="_blank"
                        style={{ height: "44px" }}
                        onClick={() => {
                          openBrowserLink(
                            "Add Extension button on bottom of page",
                            browserLink,
                            browser
                          );
                        }}
                        className="filled-button"
                      >
                        Add Extension
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {" "}
          {!MobileNewVersion ? (
            <div className="mobile-body">
              <div className="mobile-front-page-header">
                <a href="/">
                  <h1 className="mobile-logo">Pricein</h1>
                </a>
                <NavbarMobile></NavbarMobile>
              </div>
              <div className="mobile-content-body">
                <h2 className="mobile-title">
                  Same product, but for the right price.
                </h2>
                <h5 className="mobile-content-text">
                  Pricein uses AI to compare millions of items, ensuring you
                  snag the best deal effortlessly.
                </h5>
                <div className="mobile-features">
                  <div className="stat-text">1,000,000+</div>
                  <div className="normal-text">stores</div>
                </div>
                <div className="mobile-features">
                  <div className="normal-text">Save</div>
                  <div className="stat-text">20% </div>
                  <div className="normal-text"> on average</div>
                </div>
                <div className="mobile-features">
                  <div className="normal-text">Price is</div>
                  <div className="stat-text">Free</div>
                </div>
                <Link to="/iosinstall">
                  <button className="add-to-phone-button">
                    {isIOS
                      ? "Add to IPhone - IPad"
                      : isAndroid
                      ? "Add to Android"
                      : "Add to device"}
                  </button>
                </Link>
              </div>
              <div className="phone-video-body">
                <div className="phone-emu">
                  <div className="greyspots">
                    <div className="grey-dot" />
                    <div className="grey-rect" />
                  </div>
                  <video
                    ref={videoRef}
                    className="mobile-tutorial-video"
                    poster="/images/posterimage.webp"
                    muted
                    loop
                    playsInline
                  >
                    <source
                      src="/images/mobileextensiontut.mov"
                      type="video/mp4"
                    />
                  </video>
                  <div className="mobile-video-border-div" />
                </div>
              </div>
              <div className="mobile-text-content">
                <div className="mobile-content-div">
                  <h3 className="mobile-content-title">
                    Benefits of Using Pricein
                  </h3>
                  <div className="content-box hidden">
                    <div className="content-box-icon">
                      <img
                        className="extension-icon-image"
                        src="/images/coupon.webp"
                        alt="extension icon"
                      ></img>
                    </div>

                    <div className="box-subtitle">
                      Save 20% on your purchase
                    </div>
                    <div className="content-box-content">
                      In our testing, we consistently saved an average of 20% or
                      more on our purchases by utilizing our Browser extension
                      to pinpoint the same product at a reduced price on
                      alternate sites.
                    </div>
                  </div>
                  <div className="content-box hidden">
                    <div className="content-box-icon">
                      <img
                        className="extension-icon-image"
                        src="/images/shopify.webp"
                        alt="extension icon"
                      ></img>
                    </div>

                    <div className="box-subtitle">
                      Fight Copycats and Dropshippers
                    </div>
                    <div className="content-box-content">
                      If the product you desire is at risk of being a copycat or
                      dropshipping item, chances are it's available across
                      numerous online stores. Our tool helps you locate
                      budget-friendly options, including
                      direct-from-manufacturer choices.
                    </div>
                  </div>
                </div>
                <div className="mobile-content-div">
                  <h3 className="mobile-content-title">
                    Discover the Same Product for Less
                  </h3>
                  <div className="content-box hidden">
                    <div className="content-box-icon">
                      <img
                        className="extension-icon-image"
                        src="/images/extension.webp"
                        alt="extension icon"
                      ></img>
                    </div>

                    <div className="box-subtitle">Browser Extension</div>
                    <div className="content-box-content">
                      Our Browser Extension is user-friendly - attach it to your
                      browser. When you visit a product page a small icon will
                      appear. With two clicks you can then find the same product
                      cheaper.
                    </div>
                  </div>
                  <div className="content-box hidden">
                    <div className="content-box-icon">
                      <img
                        className="extension-icon-image"
                        src="/images/intelligence.webp"
                        alt="extension icon"
                      ></img>
                    </div>

                    <div className="box-subtitle">Ai search engine</div>
                    <div className="content-box-content">
                      At Pricein, we employ state-of-the-art AI technology to
                      effortlessly guide you to the perfect product on any
                      website. By analyzing both product images and titles, our
                      technology identifies items that match in appearance and
                      function.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="new-mobile-body">
              <div className="new-header-wraper-mobile">
                <div className="new-header-mobile">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <NavbarMobile></NavbarMobile>
                    <div
                      style={{ marginLeft: "20px", fontSize: "20px" }}
                      className="new-logo"
                    >
                      Pricein
                    </div>
                  </div>
                  <ReactFlagsSelect
                    className="react-select-flags-homepage"
                    style={{ border: "none" }}
                    selected={selectedCountry}
                    onSelect={(code) => setSelectedCountry(code)}
                    searchable
                    showSelectedLabel={false}
                    showOptionLabel={true}
                    selectedSize={20}
                  ></ReactFlagsSelect>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div className="new-search-field mobile">
                    <div
                      className="new-input-search-box mobile"
                      style={
                        inputText.length != 0
                          ? {
                              borderRadius: "24px 24px 0 0",
                              borderBottom: "none",
                            }
                          : {}
                      }
                    >
                      <img
                        className="new-search-icon"
                        src="/images/loupe (1).png"
                      ></img>
                      <input
                        className="new-input-field-url"
                        type="text"
                        placeholder={placeholder}
                        onKeyDown={handleKeyPress}
                        onChange={(e) => {
                          onChangeHandler(e.target.value);
                        }}
                        value={inputText}
                      ></input>
                      {!image && (
                        <form
                          action=""
                          className="img-upload-button"
                          onClick={() =>
                            document.querySelector("#image-input").click()
                          }
                        >
                          <input
                            type="file"
                            accept="image/*"
                            id="image-input"
                            onChange={({ target: { files } }) => {
                              if (files) {
                                console.log(URL.createObjectURL(files[0]));
                                setImage(URL.createObjectURL(files[0]));
                                imageFile.current = files[0];
                              }
                            }}
                            hidden
                          />

                          <img
                            className="photo-icon"
                            src="/images/cameraicon.png"
                          ></img>
                        </form>
                      )}
                      {image && (
                        <div className="img-icon homepage">
                          <img
                            src={image}
                            className="product-image"
                            onClick={() =>
                              document.querySelector("#image-input").click()
                            }
                          />

                          <img
                            src={image}
                            className="image-on-hover-icon"
                          ></img>
                          <input
                            type="file"
                            accept="image/*"
                            id="image-input"
                            onChange={({ target: { files } }) => {
                              if (files) {
                                console.log(URL.createObjectURL(files[0]));
                                setImage(URL.createObjectURL(files[0]));
                                imageFile.current = files[0];
                              }
                            }}
                            hidden
                          />
                          <button
                            onClick={() => {
                              setImage(null);
                            }}
                          >
                            <img src="images/exit-button-image.png" />
                          </button>
                        </div>
                      )}
                    </div>
                    {inputText.length != 0 && inputSuggestions ? (
                      <div className="new-suggestions">
                        {inputSuggestions.slice(0, 6).map((suggestion, i) => {
                          return (
                            <>
                              <div
                                onClick={() => {
                                  findProduct(suggestion);
                                  setInputText("");
                                }}
                                className="input-suggestion"
                                key={i}
                              >
                                {suggestion}
                              </div>
                            </>
                          );
                        })}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              <div className="new-herosection mobile">
                <div
                  style={{
                    marginTop: "40px",
                    marginBottom: "40px",
                    marginLeft: "20px",
                  }}
                >
                  <h2 className="herosection-text">
                    It's Google but for products
                  </h2>
                  <h2 className="herosection-text"></h2>
                </div>
              </div>
              <div className="products-suggestions mobile">
                <div className="products-suggestion-title">Ex: Iphone 15</div>
                <div className="products-suggestion-products mobile">
                  {exampleProducts.slice(0, nProductsShow).map((item) => (
                    <a
                      className="suggestions-products"
                      href={item.link}
                      target="_blank"
                    >
                      <div className="suggestion-image-frame">
                        <img className="suggestion-image" src={item.img}></img>
                      </div>
                      <div className="suggestion-product-price">
                        {item.price}
                      </div>
                      <div className="suggestion-product-title">
                        {item.title.slice(0, 50)}
                      </div>
                    </a>
                  ))}
                </div>
                <button
                  onClick={() => {
                    setNProductsShow(nProductsShow + 6);
                  }}
                  className="view-more-home-page"
                >
                  View more
                </button>
              </div>

              <div className="mobile-text-content">
                <div className="mobile-content-div">
                  <h3 className="mobile-content-title">
                    Benefits of Using Pricein
                  </h3>
                  <div className="content-box hidden">
                    <div className="content-box-icon">
                      <img
                        className="extension-icon-image"
                        src="/images/coupon.webp"
                        alt="extension icon"
                      ></img>
                    </div>

                    <div className="box-subtitle">
                      Save 20% on your purchase
                    </div>
                    <div className="content-box-content">
                      In our testing, we consistently saved an average of 20% or
                      more on our purchases by utilizing our Browser extension
                      to pinpoint the same product at a reduced price on
                      alternate sites.
                    </div>
                  </div>
                  <div className="content-box hidden">
                    <div className="content-box-icon">
                      <img
                        className="extension-icon-image"
                        src="/images/shopify.webp"
                        alt="extension icon"
                      ></img>
                    </div>

                    <div className="box-subtitle">
                      Fight Copycats and Dropshippers
                    </div>
                    <div className="content-box-content">
                      If the product you desire is at risk of being a copycat or
                      dropshipping item, chances are it's available across
                      numerous online stores. Our tool helps you locate
                      budget-friendly options, including
                      direct-from-manufacturer choices.
                    </div>
                  </div>
                </div>
                <div className="mobile-content-div">
                  <h3 className="mobile-content-title">
                    Discover the Same Product for Less
                  </h3>
                  <div className="content-box hidden">
                    <div className="content-box-icon">
                      <img
                        className="extension-icon-image"
                        src="/images/extension.webp"
                        alt="extension icon"
                      ></img>
                    </div>

                    <div className="box-subtitle">Browser Extension</div>
                    <div className="content-box-content">
                      Our Browser Extension is user-friendly - attach it to your
                      browser. When you visit a product page a small icon will
                      appear. With two clicks you can then find the same product
                      cheaper.
                    </div>
                  </div>
                  <div className="content-box hidden">
                    <div className="content-box-icon">
                      <img
                        className="extension-icon-image"
                        src="/images/intelligence.webp"
                        alt="extension icon"
                      ></img>
                    </div>

                    <div className="box-subtitle">Ai search engine</div>
                    <div className="content-box-content">
                      At Pricein, we employ state-of-the-art AI technology to
                      effortlessly guide you to the perfect product on any
                      website. By analyzing both product images and titles, our
                      technology identifies items that match in appearance and
                      function.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default HomePage;
