import React, { useState } from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { Link } from "react-router-dom";
import "./Navbar.css";
import { IconContext } from "react-icons";
import * as IoIcons from "react-icons/io";

function NavbarMobile() {
  const SidebarData = [
    {
      title: "Home",
      path: "/",
      icon: <AiIcons.AiFillHome />,
      cName: "nav-text",
    },
    {
      title: "Benefits",
      path: "/features",
      icon: <IoIcons.IoIosPaper />,
      cName: "nav-text",
    },
    {
      title: "FAQ",
      path: "/faq",
      icon: <FaIcons.FaCartPlus />,
      cName: "nav-text",
    },
    {
      title: "About Us",
      path: "/about",
      icon: <IoIcons.IoMdPeople />,
      cName: "nav-text",
    },
    {
      title: "Support",
      path: "/support",
      icon: <IoIcons.IoMdHelpCircle />,
      cName: "nav-text",
    },
    {
      title: "- Add to Chrome",
      path: "https://chrome.google.com/webstore/detail/pricein-same-product-lowe/afipijkhaioeopfolgnagcicgpdlcink/related?hl=sv&authuser=0",
      icon: <IoIcons.IoMdHelpCircle />,
      cName: "nav-text",
    },
    {
      title: "- Add to Iphone",
      path: "https://apps.apple.com/se/app/pricein-same-product-cheaper/id6455427237?l=en-GB",
      icon: <IoIcons.IoMdHelpCircle />,
      cName: "nav-text",
    },
    {
      title: "- Add to Safari",
      path: "https://apps.apple.com/se/app/pricein-same-product-cheaper/id6455427237?l=en-GB",
      icon: <IoIcons.IoMdHelpCircle />,
      cName: "nav-text",
    },
  ];

  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <div className="navbar">
          <Link to="#" className="menu-bars">
            <img
              src="/images/menu-button.svg"
              alt="menu button image"
              onClick={showSidebar}
            />
          </Link>
        </div>
        <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
          <ul className="nav-menu-items" onClick={showSidebar}>
            <li className="navbar-toggle">
              <Link to="#" className="menu-bars">
                <AiIcons.AiOutlineClose color="#616161" />
              </Link>
            </li>
            {SidebarData.map((item, index) => {
              return (
                <li key={index} className={item.cName}>
                  {!item.path.includes("https://") ? (
                    <Link to={item.path}>
                      <span>{item.title}</span>
                    </Link>
                  ) : (
                    <a href={item.path} target="_blank">
                      <span>{item.title}</span>
                    </a>
                  )}
                </li>
              );
            })}
          </ul>
        </nav>
      </IconContext.Provider>
    </>
  );
}

export default NavbarMobile;
