export function FilterGoogleProducts(response) {
  var nProducts = [];
  var nProductsData = [];
  // getAdvertismentProducts(response);
  const products = response.split(/(?=data-docid=)/g);

  //console.log(products[1]);
  for (var i = 0; i < products.length; i++) {
    if (products[i].includes("a8Pemb")) {
      nProducts.push(products[i]);
    }
  }

  for (var i = 0; i < nProducts.length; i++) {
    const title = googleProductGetTitle(nProducts[i]);
    const img = googleProductGetImage(response, nProducts[i]);
    const price = getAtr(nProducts[i], 'class="a8Pemb OFFNJ">', "<")
      .replace('"')
      .replace(">");
    var review = getAtr(
      nProducts[i].substr(
        nProducts[i].indexOf("out of 5") - 5,
        nProducts[i].length
      ),
      '"',
      '"'
    ).split(" ")[0];
    review = nProducts[i].includes("out of 5")
      ? review
      : nProducts[i].includes('class="Rsc7Yb">')
      ? getAtr(nProducts[i], 'class="Rsc7Yb">', "<")
      : "";
    const reviewAmountContent = nProducts[i]
      .substr(
        nProducts[i].indexOf("out of 5 stars. ") + "out of 5 stars. ".length,
        100
      )
      .split(" ")[0];

    const reviewAmount =
      nProducts[i].indexOf("out of 5 stars. ") != -1 ? reviewAmountContent : "";
    const link = googleProductGetLink(nProducts[i]);

    if (!price.includes("undefined")) {
      nProductsData.push({
        title: title,
        img: img,
        price: price,
        link: link,
        review: review.replace(/,/g, "."),
        reviewAmount: reviewAmount,
      });
    }
    // nProductsData = nProductsData.reverse();
  }
  //console.log(nProductsData);
  console.log(nProductsData.length);
  //var endTime = performance.now();
  // console.log(`Finished at ${(endTime - startTime) / 1000} seconds`);
  return nProductsData;
}

function getAdvertismentProducts(response) {
  console.log("getAdvertismentProducts");
  let products = [];
  let productsHTML = [];
  productsHTML = response.split(/class="KZmu8e/g);
  console.log(productsHTML);
  console.log(productsHTML.length);
  for (var i = 0; i < productsHTML.length; i++) {
    const title = googleProductGetTitle(productsHTML[i]);
    const img = getAtr(
      productsHTML[i],
      productsHTML[i].includes('<img src="', "<")
    );
    const price = getAtr(
      productsHTML[i],
      productsHTML[i].includes('class="a8Pemb OFFNJ">')
        ? 'class="a8Pemb OFFNJ">'
        : 'class="translate-content">',
      "<"
    )
      .replace('"')
      .replace(">");

    const link = googleProductGetLink(productsHTML[i]);

    products.push({
      title: title,
      img: img,
      price: price,
      link: link,
    });
  }
  console.log(products);
  return products;
}
function googleProductGetImage(response, product) {
  const imageLinks = response.substr(
    response.indexOf("var _u='https://encrypted")
  );
  const id = getAtr(product, 'data-docid="', '"');
  const reversed = imageLinks.split("").reverse().join("");
  const index = reversed.indexOf(id.split("").reverse().join("")) + id.length;
  const start = reversed.indexOf("'", index + 3) + 1;
  const end = reversed.indexOf("//:", start + 10) + 8;
  const url = reversed
    .substr(start, end - start)
    .split("")
    .reverse()
    .join("")
    .replace(/\\x3d/g, "=")
    .replace(/\\x26/g, "&")
    .replace(/\\x26/g, "&");
  return url;
}
function googleProductGetTitle(product) {
  var tag;
  for (var i = 1; i < 6; i++) {
    if (product.includes("<h" + i.toString())) {
      tag = "<h" + i.toString();
    }
  }
  const start = product.indexOf(">", product.indexOf(tag) + 2) + 1;
  const end = product.indexOf("</", start);
  const title = product.substr(start, end - start);
  return title;
}
function googleProductGetLink(product) {
  const id = 'class="shntl';
  const reversed = product.split("").reverse().join("");
  const index = reversed.indexOf(id.split("").reverse().join("")) + id.length;
  const start = reversed.indexOf('"', index) + 1;
  const end = reversed.indexOf('"', start + 10);
  var url =
    "https://www.google.com" +
    reversed
      .substr(start, end - start)
      .split("")
      .reverse()
      .join("")
      .replace(/\\x3d/g, "=")
      .replace(/\\x26/g, "&")
      .replace(/&amp;/g, "&");

  return url;
}

function getAtr(content, startTag, endTag, before = false) {
  const start =
    before == false
      ? content.indexOf(startTag) + startTag.length
      : content.indexOf(startTag);
  const end = content.indexOf(endTag, start);
  const text = content.substr(start, end - start);
  return text;
}

export function FilterAmazonProducts(response, amazonBase) {
  var nProducts = [];
  var nProductsData = [];
  const products = response.split('"dispatch"');
  console.log(products[1]);

  for (var i = 0; i < products.length; i++) {
    if (products[i].includes("a-offscreen")) {
      nProducts.push(products[i]);
    }
  }
  for (var i = 0; i < nProducts.length; i++) {
    const title = trimResults(getAtr(nProducts[i], 'alt=\\"', '\\"')).replace(
      /Sponsored Ad - /g,
      ""
    );
    const img = trimResults(getAtr(nProducts[i], 'srcset=\\"', " "));
    const price = trimResults(getAtr(nProducts[i], '"a-offscreen\\">', "<"));
    const asin = trimResults(getAtr(nProducts[i], '"asin" : "', '",'));
    const link = amazonBase + "/dp/" + asin + "/";

    const review = trimResults(
      getAtr(nProducts[i], '"a-icon-alt\\">', "<").trim().split(" ")[0]
    ).replace(/,/g, ".");

    var reviewAmount = "";
    if (nProducts[i].includes('a-size-base s-underline-text\\">')) {
      reviewAmount = getAtr(
        nProducts[i],
        'a-size-base s-underline-text\\">',
        "<"
      );
    }
    if (
      nProducts[i].includes(
        "a-size-base puis-light-weight-text s-link-centralized-style"
      )
    ) {
      var reviewAmount = getAtr(
        nProducts[i],
        'a-size-base puis-light-weight-text s-link-centralized-style\\">',
        "<"
      );
    }
    if (!link.includes("/dp//"))
      nProductsData.push({
        title: title,
        img: img,
        price: price,
        link: link,
        review: review,
        reviewAmount: reviewAmount,
      });
  }
  console.log(nProductsData);

  return nProductsData;
}

function trimResults(content) {
  return content.replace(/&amp;/g, "&").trim();
}

export function FilterAliexpressProducts(response) {
  let products = [];
  try {
    const data = response.data.result.mods.itemList.content;
    console.log(data);
    for (let i = 0; i < data.length; i++) {
      const prod = data[i];
      const img = prod.image.imgUrl;
      const title = prod.title.displayTitle;
      const price = prod.prices.salePrice.formattedPrice;
      const link =
        "https://www.aliexpress.com/item/" + prod.productId + ".html";
      products.push({
        title: title,
        img: img,
        price: price,
        link: link,
      });
    }
    console.log(products);
    return products;
  } catch (error) {
    return [];
  }
}
