import React from "react";
import "../HomePage.css";
import HomePageHeader from "../widgets/HomePageHeader";
import NavbarMobile from "./NavbarMobile";
function Privacy() {
  var windowWidth = window.innerWidth < 1000;
  return (
    <div>
      {windowWidth ? (
        <div className="mobile-front-page-header">
          <a href="/">
            <h1 className="mobile-logo">Pricein</h1>
          </a>
          <NavbarMobile></NavbarMobile>
        </div>
      ) : (
        <HomePageHeader></HomePageHeader>
      )}
      <div className="about-us-body">
        <div className="about-us-title content-subject-title">
          Privacy Policy
        </div>
        <div className="about-us-content-body content-box-content">
          We do not collect any personal data at all. We only keep track of what
          country the products should be found in.
        </div>
      </div>
    </div>
  );
}

export default Privacy;
