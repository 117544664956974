import React from "react";
import "./CompareProduct.css";

function CompareProduct(params) {
  console.log(params);
  return (
    <div className="compare-products-overlay">
      <div className="compare-products-widget">
        <button className="exit-button">
          <img src="https://cdn-icons-png.flaticon.com/512/2997/2997911.png" />
        </button>
        <div className="compare-images">
          <div className="img-plus-title">
            <h4>Original</h4>
            <img className="img-compare" src={params.oImg}></img>
          </div>
          <div className="img-plus-title">
            <h4>{params.store}</h4>
            <img className="img-compare" src={params.cImg}></img>
          </div>
        </div>

        <div className="compare-data">
          <div className="compare-row">
            <p className="label">Price</p>
            <div className="data-values">
              <p className="val-price">{params.price[0]}</p>
              <p className="val-price">{params.price[1]}</p>
            </div>
          </div>
          <div className="line-div"></div>
          <div className="compare-row">
            <p className="label">Title</p>
            <div className="data-values">
              <p className="val-title">{params.title[0]}</p>
              <p className="val-title">{params.title[1]}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompareProduct;
