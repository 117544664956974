import React, { useRef, useState } from "react";
import HomePageHeader from "../widgets/HomePageHeader";
import "./FAQ.css";
import NavbarMobile from "./NavbarMobile";
function FAQ() {
  const [active1, setActive1] = useState(false);
  const [active2, setActive2] = useState(false);
  const [active3, setActive3] = useState(false);
  const [active4, setActive4] = useState(false);
  var windowWidth = window.innerWidth < 1000;
  return (
    <div>
      {windowWidth ? (
        <div className="mobile-front-page-header">
          <a href="/">
            <h1 className="mobile-logo">Pricein</h1>
          </a>
          <NavbarMobile></NavbarMobile>
        </div>
      ) : (
        <HomePageHeader></HomePageHeader>
      )}
      <div className="faq-body">
        <div className="about-us-title content-subject-title">FAQs</div>
        <div
          className={active1 ? "faq-questions active" : "faq-questions"}
          onClick={() => {
            setActive1(!active1);
            console.log("asdasd");
          }}
        >
          <div className="faq-q">
            <div className="faq-q-title">
              The Blue Pricein Icon Doesn't Appear:
            </div>
            <svg width="15px" height="10px" viewBox="0 0 42 25">
              <path
                d="M3 3L21 21L39 3"
                stroke="white"
                strokeWidth="7"
                strokeLinecap="round"
              ></path>
            </svg>
          </div>
          <div className="faq-answer">
            <p>
              Click on the extensions icon located in the upper right corner.
              Find and select the Pricein extension.
            </p>
          </div>
        </div>
        <div
          className={active2 ? "faq-questions active" : "faq-questions"}
          onClick={() => {
            setActive2(!active2);
          }}
        >
          <div className="faq-q">
            <div className="faq-q-title">Does it work in all countries</div>
            <svg width="15px" height="10px" viewBox="0 0 42 25">
              <path
                d="M3 3L21 21L39 3"
                stroke="white"
                strokeWidth="7"
                strokeLinecap="round"
              ></path>
            </svg>
          </div>
          <div className="faq-answer">
            <p>Yes! Pricein works in all mayor countries around the world.</p>
          </div>
        </div>
        <div
          className={active4 ? "faq-questions active" : "faq-questions"}
          onClick={() => {
            setActive4(!active4);
          }}
        >
          <div className="faq-q">
            <div className="faq-q-title">
              Compatibility with Safari and Edge:
            </div>
            <svg width="15px" height="10px" viewBox="0 0 42 25">
              <path
                d="M3 3L21 21L39 3"
                stroke="white"
                strokeWidth="7"
                strokeLinecap="round"
              ></path>
            </svg>
          </div>
          <div className="faq-answer">
            <p>
              Certainly! Currently, the Pricein extension is compatible with
              Chrome, Safari, and Edge browsers.
            </p>
          </div>
        </div>
        <div
          className={active3 ? "faq-questions active" : "faq-questions"}
          onClick={() => {
            setActive3(!active3);
          }}
        >
          <div className="faq-q">
            <div className="faq-q-title">Is it free</div>
            <svg width="15px" height="10px" viewBox="0 0 42 25">
              <path
                d="M3 3L21 21L39 3"
                stroke="white"
                strokeWidth="7"
                strokeLinecap="round"
              ></path>
            </svg>
          </div>
          <div className="faq-answer">
            <p>
              Absolutely, Pricein is completely free to use! Enjoy all its
              benefits without any charges.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FAQ;
