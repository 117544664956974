import React from "react";
import Footer from "../components/footer";
import Icon from "../components/icons";

export function FooterContainer() {
  return (
    <Footer>
      <Footer.Wrapper>
        <Footer.Row>
          <Footer.Column>
            <Footer.Title>About Us</Footer.Title>
            <Footer.Link href="/about">About</Footer.Link>
            <Footer.Link href="/support">Support</Footer.Link>
            <Footer.Link href="mailto:hello@pricein.ai">
              hello@pricein.ai
            </Footer.Link>
          </Footer.Column>
          <Footer.Column>
            <Footer.Title>Extension</Footer.Title>
            <Footer.Link href="/howtouse">How to Use</Footer.Link>
            <Footer.Link href="/features">Benefits</Footer.Link>
            <Footer.Link href="/faq">FAQ</Footer.Link>
          </Footer.Column>
          <Footer.Column>
            <Footer.Title>Terms</Footer.Title>
            <Footer.Link href="/privacy">Privacy</Footer.Link>
          </Footer.Column>
          <Footer.Column>
            <Footer.Title>Social</Footer.Title>
            <Footer.Link href="#">Facebook</Footer.Link>
            <Footer.Link href="#">Instagram</Footer.Link>
            <Footer.Link href="#">Youtube</Footer.Link>
            <Footer.Link href="#">Twitter</Footer.Link>
          </Footer.Column>
        </Footer.Row>
      </Footer.Wrapper>
    </Footer>
  );
}
