import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { renderToString } from "react-dom/server";
import { useSelector } from "react-redux";
import { FaStarHalf, FaStar, FaRegStar, FaStarHalfAlt } from "react-icons/fa";
import CompareProduct from "./widgets/CompareProduct";
import axios from "axios";
import WebsiteByImage from "./WebsiteByImage";
import ImageWithFallback from "./widgets/ImageWithFallback";
import { Event } from "../additionalFunctions/GoogleAnalyticsFunctions";
const ServerUrl = "http://52.87.230.144:8080/";

function sendClickEvent(link, store, resultNumber) {
  const eventData = "link=" + link;
  const eventCatygory = store + " resultNumber=" + resultNumber;
  Event(eventCatygory, "Product Clicked", eventData, eventData);
}
const openLink = (link, store, resultNumber) => {
  window.open(link, "_blank");
  sendClickEvent(link, store, resultNumber);
};

const ProductComponent = (params) => {
  const mobileMode = params.mobileMode;
  function scrollFunc(cls) {
    const yOffset = -100;
    var elmntToView = document.getElementsByClassName(cls)[0];
    if (elmntToView) {
      const y =
        elmntToView.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  }
  const [sortAfterBool, setSortAfterBool] = useState(false);
  const [storeSort, setStoreSort] = useState([]);
  const [scrolled, setScrolled] = useState(false);
  const [sortVal, setSortVal] = useState(1);
  const [viewMoreMobile, setViewMoreMobile] = useState(false);
  const [sortOpen, setSortOpen] = useState(1);
  const [stores, setStores] = useState([{ name: "Amazon", checked: true }]);

  const queryParams = new URLSearchParams(window.location.search);
  const titleParam = (queryParams.get("title") || "").replace(/ /g, "+");
  const imgParam = queryParams.get("img");
  const priceParam = queryParams.get("price") || "??";
  const ccParam = queryParams.get("cc") || "us";
  const imgOnlyParam = (queryParams.get("imgonly") || "false") == "true";
  const storeListingUrl =
    "/product/finder/storelisting/?img=" +
    imgParam +
    "&title=" +
    titleParam +
    "&price=" +
    priceParam +
    "&cc=" +
    ccParam;

  function sortAfterClicked(num) {
    setSortAfterBool(false);
    setSortVal(num);
  }
  function getSortAfterTitle() {
    switch (sortVal) {
      case 1:
        return "Best Match";
        break;
      case 2:
        return "Average Rating";
        break;
      case 3:
        return "Price Low - High";
        break;
      case 4:
        return "Price High - Low";
        break;
      case 5:
        return "Number of Reviews";
        break;
    }
  }

  function mixProducts(...arrays) {
    const mixedArray = [];
    const maxLength = Math.max(...arrays.map((arr) => arr.length));

    for (let i = 0; i < maxLength; i++) {
      for (const array of arrays) {
        if (i < array.length) {
          mixedArray.push(array[i]);
        }
      }
    }

    return mixedArray;
  }

  function compareReviews(a, b) {
    var aprice = "review" in a ? a.review : a.averageRating;
    var bprice = "review" in b ? b.review : b.averageRating;
    if (Number(aprice) > Number(bprice)) {
      return -1;
    }
    if (Number(aprice) < Number(bprice)) {
      return 1;
    }

    return 0;
  }
  function comparePriceLowToHigh(a, b) {
    var aprice = ("price" in a ? a.price : "100000000" || "0").toString();
    var bprice = ("price" in b ? b.price : "100000000" || "0").toString();
    aprice = aprice.replace(/[^\d.-]/g, "").replace(/,/, "");
    bprice = bprice.replace(/[^\d.-]/g, "").replace(/,/, "");
    if (Number(aprice) < Number(bprice)) {
      return -1;
    }
    if (Number(aprice) > Number(bprice)) {
      return 1;
    }
    return 0;
  }
  function comparePriceHighToLow(a, b) {
    var aprice = (
      "price" in a ? a.price : a.productMinPrice.value || "0"
    ).toString();
    var bprice = (
      "price" in b ? b.price : b.productMinPrice.value || "0"
    ).toString();
    aprice = aprice.replace(/[^\d.-]/g, "").replace(/,/, "");
    bprice = bprice.replace(/[^\d.-]/g, "").replace(/,/, "");
    if (Number(aprice) > Number(bprice)) {
      return -1;
    }
    if (Number(aprice) < Number(bprice)) {
      return 1;
    }
    return 0;
  }
  function compareReviewsAmount(a, b) {
    var aprice = (
      "reviewAmount" in a ? a.reviewAmount : a.totalOrders || "0"
    ).toString();
    var bprice = (
      "reviewAmount" in b ? b.reviewAmount : b.totalOrders || "0"
    ).toString();
    aprice = aprice.replace(/[^\d.-]/g, "").replace(/,/, "");
    bprice = bprice.replace(/[^\d.-]/g, "").replace(/,/, "");
    if (Number(aprice) > Number(bprice)) {
      return -1;
    }
    if (Number(aprice) < Number(bprice)) {
      return 1;
    }
    return 0;
  }
  function getSortFunc() {
    switch (sortVal) {
      case 1:
        return sortBySimilarityScore;

        break;
      case 2:
        return compareReviews;
        break;
      case 3:
        return comparePriceLowToHigh;
        break;
      case 4:
        return comparePriceHighToLow;
        break;
      case 5:
        return compareReviewsAmount;
        break;
    }
  }
  function addProductCompare(e, img, title, price, store) {
    e.stopPropagation();
    var compareProduct = document.createElement("div");
    compareProduct.innerHTML = renderToString(
      <CompareProduct
        oImg={imgParam}
        cImg={img}
        price={[priceParam, price]}
        store={store}
        title={[
          titleParam.replace(/\+/g, " ").substr(0, 100),
          title.substr(0, 100),
        ]}
      />
    );
    const button = compareProduct.getElementsByClassName("exit-button")[0];
    button.onclick = (e) => {
      e.stopPropagation();
      const element = document.getElementsByClassName(
        "compare-products-overlay"
      )[0];
      element.remove();
    };

    const button2 = compareProduct.getElementsByClassName(
      "compare-products-overlay"
    )[0];
    button2.onclick = (e) => {
      e.stopPropagation();
      const element2 = document.getElementsByClassName(
        "compare-products-overlay"
      )[0];
      element2.remove();
    };

    const button3 = compareProduct.getElementsByClassName(
      "compare-products-widget"
    )[0];
    button3.onclick = (e) => {
      e.stopPropagation();
    };
    document
      .getElementsByClassName("App")[0]
      .prepend(compareProduct.firstChild);
  }

  let gridHeaderArray = [];
  let classNames = [];
  let viewMoreStoreNames = [];
  const noReviews = [0, 0, 0, 0, 0];

  const LoadingArray = [
    { loading: true },
    { loading: true },
    { loading: true },
    { loading: true },
  ];
  function roundHalf(num) {
    return Math.round(num * 2) / 2;
  }
  function starRating(review) {
    const reviewHalf = roundHalf(review);
    switch (reviewHalf) {
      case 0:
        return [];
      case 0.5:
        return [1];
      case 1:
        return [0];
      case 1.5:
        return [0, 1];
      case 2.0:
        return [0, 0];
      case 2.5:
        return [0, 0, 1];
      case 3.0:
        return [0, 0, 0];
      case 3.5:
        return [0, 0, 0, 1];
      case 4.0:
        return [0, 0, 0, 0];
      case 4.5:
        return [0, 0, 0, 0, 1];
      case 5:
        return [0, 0, 0, 0, 0];
    }
    return [];
  }

  const amazonProductsI = useSelector(
    (state) => state.amazonProductsI.products
  );

  const WebsiteByImageProducts = useSelector(
    (state) => state.websiteByImageProducts.products
  );
  const WebsiteByImageProductsCC = useSelector(
    (state) => state.websiteByImageProductsCC.products
  );
  const aliexpressImageProducts = useSelector(
    (state) => state.aliexpressProductsImage.products
  );
  const aliexpressIProducts = useSelector(
    (state) => state.aliexpressProductsI.products
  );
  const googleProductsI = useSelector(
    (state) => state.googleProductsI.products
  );

  const aliexpressICCProducts = useSelector(
    (state) => state.aliexpressProductsICC.products
  );
  const googleProductsICC = useSelector(
    (state) => state.googleProductsICC.products
  );
  const amazonProductsICC = useSelector(
    (state) => state.amazonProductsICC.products
  );
  const googleProductsMax = useSelector(
    (state) => state.googleProductsMax.products
  );
  const amazonProducts = useSelector((state) => state.amazonProducts.products);

  const walmartProducts = useSelector(
    (state) => state.walmartProducts.products
  );

  const productSearchProudcts = useSelector(
    (state) => state.productSearch.products
  );

  const googleProducts = useSelector((state) => state.googleProducts.products);

  const aliexpressProducts = mixProducts(
    aliexpressImageProducts,
    mixProducts(
      useSelector((state) => state.aliexpressProducts.products),
      aliexpressIProducts
    )
  );

  const plusIconUrl = "images/plusicon.png";
  const minusIconUrl = "images/minusicon.png";

  //See if need mobile view

  // Set amount of search results
  const sortBySimilarityScore = (a, b) => {
    if (!a.similarityScore) return 1;
    if (!b.similarityScore) return -1;

    return a.similarityScore - b.similarityScore;
  };
  const sortByStoreCount = (a, b) => {
    if (a.storeCount > b.storeCount) {
      return -1;
    }
    if (a.storeCount < b.storeCount) {
      return 1;
    }
    return 0;
  };
  var products = [];
  var f = [];
  const sortFunc = getSortFunc();
  /*
  if (WebsiteByImageProducts.length != 0 && !scrolled) {
    scrollFunc("storesByImageTitle");
    setScrolled(true);
  }*/

  gridHeaderArray.push(
    <Link to={storeListingUrl + "&storename=allstores"}>
      <div className="brand-circle">All Stores</div>
    </Link>
  );
  classNames.push("other-store-section-container");
  viewMoreStoreNames.push("allstores");
  var p = [];
  console.log(
    "Allproducts: ",
    mixProducts(
      [].concat(googleProducts),
      [].concat(amazonProducts),
      [].concat(googleProductsICC),
      [].concat(walmartProducts),
      [].concat(productSearchProudcts),
      [].concat(googleProductsI),
      [].concat(amazonProductsI)
    ).concat(aliexpressProducts)
  );
  p = []
    .concat(googleProducts)
    .concat(
      mixProducts(
        [].concat(amazonProducts),
        [].concat(googleProductsICC),
        [].concat(walmartProducts),
        [].concat(productSearchProudcts),
        [].concat(googleProductsI),
        [].concat(amazonProductsI)
      )
    )
    .sort(sortFunc)
    .concat(aliexpressProducts);

  if (!viewMoreMobile) p = p.slice(0, 24);
  products = products.concat(p);

  if (amazonProducts.length != 0) {
    viewMoreStoreNames.push("amazon");
    gridHeaderArray.push(
      <Link to={storeListingUrl + "&storename=amazon"}>
        <div className="brand-circle">
          <img
            src="/images/01_amazon_smile_RGB_SQUID._TTW_.webp"
            alt="amazon icon"
          ></img>
        </div>
      </Link>
    );
    classNames.push("amazon-section-container");
    var p = [];
    p = p.concat(amazonProducts).sort(sortFunc).slice(0, 4);
    products = products.concat(p);
  }

  if (aliexpressProducts.length != 0) {
    viewMoreStoreNames.push("aliexpress");
    gridHeaderArray.push(
      <Link to={storeListingUrl + "&storename=aliexpress"}>
        <div className="brand-circle">
          <img src="/images/aliexpresslogo.webp" alt="aliexpress icon"></img>
        </div>
      </Link>
    );
    classNames.push("aliexpress-section-container");
    var p = [];
    p = p.concat(aliexpressProducts).sort(sortFunc).slice(0, 4);
    products = products.concat(p);
  }
  console.log("products length", products.length);
  for (let index = 0; index <= (24 - products.length) / 4; index++) {
    products = products.concat(LoadingArray);
  }
  console.log("Products", products);
  let imageList = [];
  let resultNumber = 0;

  let storesList = [];

  function sortByStore(products) {
    console.log("Stores filter", storeSort);
    let filteredProducts = [];
    if (storeSort.length == 0) return products;
    for (let i = 0; i < products.length; i++) {
      const product = products[i];
      for (let s = 0; s < storeSort.length; s++) {
        let link = product.link;
        if (storeSort[s].includes("aliexpress")) {
          if (product.productId) {
            filteredProducts.push(product);
          }
        }
        if (product.link) {
          if (link.includes(storeSort[s])) {
            filteredProducts.push(product);
          }
        }
      }
    }

    return filteredProducts;
  }
  console.log("Store sort", sortByStore(products));

  let renderList = products.map((product) => {
    resultNumber++;
    if (!("loading" in product)) {
      var title,
        img,
        price,
        link,
        domain,
        review,
        ratingAvrage,
        reviewAmount,
        store,
        reviewVal;
      if ("productMinPrice" in product) {
        reviewVal = isNaN(product.averageRating) ? 0 : product.averageRating;
        var orders = product.totalOrders || "0";
        title = product.title;
        img = product.imageUrl;
        img = img.replace(/https:https/, "https");
        price = "$" + product.productMinPrice.value;
        link = "https://www.aliexpress.com/item/" + product.productId + ".html";
        domain = "aliexpress.com";
        review = starRating(Number(reviewVal));
        ratingAvrage = Number(reviewVal);
        reviewAmount = "(" + orders + " orders)";
      } else {
        reviewVal = isNaN(product.review) ? 0 : product.review;
        title = product.title;
        img = product.img;
        img = img.replace(/https:https/, "https");
        if (img.includes("walmart")) {
          if (
            img.includes(title.split(" ")[0]) &&
            img.includes(title.split(" ")[1])
          ) {
            img = img.replace(/\/asr\//, "/seo/");
          } else {
            img = img.replace(/\/seo\//, "/asr/");
          }
        }
        if (img.includes("_AC_SX148_SY213_QL70_")) {
          img = img.replace(/_AC_SX148_SY213_QL70_/, "_AC_UL1200_FMwebp_QL65_");
        }
        if (img.includes("_AC_SR175,263_QL70_")) {
          img = img.replace(/_AC_SR175,263_QL70_/, "_AC_UL1200_FMwebp_QL65_");
        }

        price = product.price;
        link = product.link;

        review = starRating(Number(reviewVal));
        ratingAvrage = Number(reviewVal);
        reviewAmount = product.reviewAmount || "";
        try {
          if (link.includes("google.com")) {
            domain = new URL(link.split("url=")[1]).hostname.replace(
              /www./,
              ""
            );
          } else {
            domain = new URL(link).hostname.replace(/www./, "");
          }
        } catch (error) {
          domain = link;
        }
        //console.log(link);
        if (link.includes("shopping/product/")) {
          domain = "shopping.google";
        } else {
          try {
            if (link.includes("google.com")) {
              domain = new URL(link.split("url=")[1]).hostname.replace(
                /www./,
                ""
              );
            } else {
              domain = new URL(link).hostname.replace(/www./, "");
            }
          } catch (error) {
            domain = "store";
          }
        }
      }
      if (link.includes("click.aliexpress")) {
        domain = "aliexpress.com";
      }
      if (link.includes("anemo.productsearch.app")) {
        domain = "amazon.com";
      }
      if (link.includes("api.aliseeks.com")) {
        domain = "aliexpress.com";
      }

      var storeReverse = domain.split("").reverse().join("");
      store = storeReverse
        .substr(
          storeReverse.indexOf(".") + 1,
          storeReverse.length - storeReverse.indexOf(".")
        )
        .split("")
        .reverse()
        .join("");
      let storeCount = 0;
      for (let index = 0; index < storesList.length; index++) {
        if (storesList[index].store == store) {
          storeCount = storesList[index].storeCount + 1;
          storesList[index].storeCount = storeCount;
        }
      }
      if (storeCount == 0) {
        storesList.push({ store: store, storeCount: 1 });
      }

      ratingAvrage = ratingAvrage == "5" ? "5.0" : ratingAvrage;
      // const { id, title, image, price, category } = product;
      reviewAmount =
        reviewAmount.includes("(") || reviewAmount == ""
          ? reviewAmount
          : "(" + reviewAmount + ")";

      imageList.push(img);
      const handleImageError = (e) => {
        console.log("Image Error");
        e.src = "https://cdn-icons-png.flaticon.com/512/1170/1170678.png";
      };
      //console.log("storeSort", storeSort);
      // console.log("store", store);
      if (storeSort.length != 0) {
        if (!storeSort.includes(store)) {
          return null;
        }
      }
      return (
        <div className="four wide column">
          <a
            className="a-link"
            onClick={() => openLink(link, store, resultNumber)}
            target="_blank"
          >
            <div className="ui link cards">
              <div className="cardd">
                <div className="overlay">
                  <div className="action-buttons">
                    <div className="action-button-container">
                      <div className="action-button">
                        <img src="https://cdn-icons-png.flaticon.com/512/1170/1170678.png"></img>
                      </div>
                      <div className="tool-tip">Go to store</div>
                    </div>
                    <div className="action-button-container">
                      <div
                        className="action-button"
                        onClick={(e) =>
                          addProductCompare(e, img, title, price, store)
                        }
                      >
                        <img src="https://cdn-icons-png.flaticon.com/512/3793/3793562.png"></img>
                      </div>

                      <div className="tool-tip">
                        Compare to original product
                      </div>
                    </div>
                    <div className="action-button-container">
                      <div className="action-button">
                        <img src="https://cdn-icons-png.flaticon.com/512/5186/5186087.png"></img>
                      </div>
                      <div className="tool-tip">Save for later</div>
                    </div>
                  </div>
                </div>
                <div className="image">
                  <img src={img} alt={title.substr(0, 30)}></img>
                </div>

                <div className="meta">{domain}</div>
                <div className="header">{title.substr(0, 50)}</div>
                <div className="rating">
                  {ratingAvrage !== 0 && (
                    <p className="avarage-rating">{ratingAvrage}</p>
                  )}
                  {review.map((x, idx) => (
                    <div>
                      {x == 0 && <FaStar size={12} color="black"></FaStar>}
                      {x == 1 && (
                        <FaStarHalf size={12} color="black"></FaStarHalf>
                      )}
                    </div>
                  ))}
                  {reviewAmount != "" && (
                    <p className="number-rating">{reviewAmount}</p>
                  )}
                </div>
                <div className="meta price">{price} </div>
              </div>
            </div>
          </a>
        </div>
      );
    } else {
      return (
        <div className="four wide column">
          <a target="_blank">
            <div className="ui link cards">
              <div className="cardd">
                <div className="image">
                  <div className="skeleton" />
                </div>

                <div className="content">
                  <div className="skeleton skeleton-text"></div>
                  <div className="skeleton skeleton-text"></div>
                  <div className="skeleton skeleton-text"> </div>
                  <div className="skeleton skeleton-text"></div>
                </div>
              </div>
            </div>
          </a>
        </div>
      );
    }
  });
  renderList = renderList.filter((item) => item !== null);
  if (!mobileMode) {
    return (
      <>
        <div className="sort-after-main">
          <div className="sort-after">
            <div
              className="sort-after-texts"
              onClick={() => setSortAfterBool(!sortAfterBool)}
            >
              <p className="sort-after-p">Sort after:</p>
              <p className="sort-after-p sort-after-text-button">
                {getSortAfterTitle()}
              </p>
              <div class="arrow-down" />
            </div>
            {sortAfterBool && (
              <div className="sort-after-container">
                <button
                  onClick={() => sortAfterClicked(1)}
                  className="sort-after-button"
                >
                  Best Match
                </button>

                <button
                  onClick={() => sortAfterClicked(3)}
                  className="sort-after-button"
                >
                  Price Low - High
                </button>
                <button
                  onClick={() => sortAfterClicked(4)}
                  className="sort-after-button"
                >
                  Price High - Low
                </button>
                <button
                  onClick={() => sortAfterClicked(2)}
                  className="sort-after-button"
                >
                  Avrage Rating
                </button>
                <button
                  onClick={() => sortAfterClicked(5)}
                  className="sort-after-button"
                >
                  Number of Reviews
                </button>
              </div>
            )}
          </div>
        </div>
        {imgOnlyParam && (
          <>
            <h2 className="storesByImageTitle">
              <span className="title-section-stores-by-title">
                Stores with matching
              </span>
              <span className="title-section-stores-by-title">
                {" "}
                product images
              </span>
            </h2>
            <WebsiteByImage
              data={[]
                .concat(WebsiteByImageProductsCC)
                .concat(WebsiteByImageProducts)}
            ></WebsiteByImage>
          </>
        )}
        <div className={classNames[0]}>
          <div className="products-first-row">
            <div className="sorting-div">
              <div className="sorting-part" style={{ height: "auto" }}>
                <div
                  className="sorting-part-data"
                  onClick={() => {
                    setSortOpen(sortOpen == 0 ? 1 : 0);
                  }}
                >
                  <div className="sorting-part-text">Stores</div>
                  <img
                    src={
                      sortOpen == 0 || sortOpen == 2
                        ? plusIconUrl
                        : minusIconUrl
                    }
                    className="sorting-part-sign"
                  ></img>
                </div>
                {sortOpen == 1 && (
                  <div className="stores-filter-list">
                    {storesList.sort(sortByStoreCount).map((item) => (
                      <div className="one-store-filter">
                        <input
                          type="checkbox"
                          onClick={() => {
                            if (storeSort.length == 0) {
                              setStoreSort([item.store]);
                            } else if (!storeSort.includes(item.store)) {
                              const updatedStoreSort = [
                                ...storeSort,
                                item.store,
                              ];
                              console.log("adding", updatedStoreSort);
                              setStoreSort(updatedStoreSort);
                            } else {
                              const indexToRemove = storeSort.indexOf(
                                item.store
                              );
                              if (indexToRemove !== -1) {
                                console.log("removing", item.store);
                                const updatedStoreSort = [...storeSort];
                                updatedStoreSort.splice(indexToRemove, 1);
                                setStoreSort(updatedStoreSort);
                              }
                            }
                          }}
                        />
                        <div className="storeName">{item.store}</div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="sorting-part" style={{ height: "auto" }}>
                <div
                  className="sorting-part-data"
                  onClick={() => {
                    setSortOpen(sortOpen == 0 || sortOpen == 1 ? 2 : 0);
                  }}
                >
                  <div className="sorting-part-text">Price</div>
                  <img
                    src={
                      sortOpen == 0 || sortOpen == 1
                        ? plusIconUrl
                        : minusIconUrl
                    }
                    className="sorting-part-sign"
                  ></img>
                </div>
                {sortOpen == 2 && (
                  <div className="stores-filter-list">
                    <div className="one-store-filter">
                      <input
                        type="checkbox"
                        onClick={() => sortAfterClicked(sortVal == 1 ? 1 : 1)}
                      />
                      <div className="storeName">Best Match</div>
                    </div>
                    <div className="one-store-filter">
                      <input
                        type="checkbox"
                        onClick={() => sortAfterClicked(sortVal == 3 ? 1 : 3)}
                      />
                      <div className="storeName">Price Low - High</div>
                    </div>
                    <div className="one-store-filter">
                      <input
                        type="checkbox"
                        onClick={() => sortAfterClicked(sortVal == 4 ? 1 : 4)}
                      />
                      <div className="storeName">Price High - Low</div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="ui grid container three-row">
              {renderList.slice(0, 6)}
            </div>
          </div>
          <div className="ui grid container">
            {!viewMoreMobile
              ? renderList.slice(6, 26)
              : renderList.slice(6, renderList.length)}
          </div>
        </div>

        <button
          onClick={() => {
            setViewMoreMobile(true);
          }}
          className="view-more"
        >
          view more
        </button>

        {!imgOnlyParam && WebsiteByImageProducts.length > 0 ? (
          <>
            <h2 className="storesByImageTitle">
              <span className="title-section-stores-by-title">
                Stores with matching
              </span>
              <span className="title-section-stores-by-title">
                {" "}
                product images
              </span>
            </h2>
            <WebsiteByImage
              data={[]
                .concat(WebsiteByImageProductsCC)
                .concat(WebsiteByImageProducts)}
            ></WebsiteByImage>
          </>
        ) : (
          <></>
        )}
        <div style={{ height: "100px" }}></div>
        {/* This is a single-line comment in JSX 
        <div className={classNames[1]}>
          {gridHeaderArray[1]}
          <div className="ui grid container">{renderList.slice(24, 28)}</div>
        </div>
        <Link to={storeListingUrl + "&storename=" + viewMoreStoreNames[1]}>
          <button className="view-more">view more</button>
        </Link>

        <div className={classNames[2]}>
          {gridHeaderArray[2]}
          <div className="ui grid container">{renderList.slice(28, 32)}</div>
        </div>
        <Link to={storeListingUrl + "&storename=" + viewMoreStoreNames[2]}>
          <button className="view-more">view more</button>
        </Link>*/}
      </>
    );
  } else {
    return (
      <>
        <div className="filter-plus-items">
          <div className="items-text">{"Items (20)"}</div>
          <div className="sort-after mobile">
            <div
              className="sort-after-texts"
              onClick={() => setSortAfterBool(!sortAfterBool)}
            >
              <p className="sort-after-p">Sort after:</p>
              <p className="sort-after-p sort-after-text-button">
                {getSortAfterTitle()}
              </p>
              <div class="arrow-down" />
            </div>
            {sortAfterBool && (
              <div className="sort-after-container">
                <button
                  onClick={() => sortAfterClicked(1)}
                  className="sort-after-button"
                >
                  Best Match
                </button>

                <button
                  onClick={() => sortAfterClicked(3)}
                  className="sort-after-button"
                >
                  Price Low - High
                </button>
                <button
                  onClick={() => sortAfterClicked(4)}
                  className="sort-after-button"
                >
                  Price High - Low
                </button>
                <button
                  onClick={() => sortAfterClicked(2)}
                  className="sort-after-button"
                >
                  Avrage Rating
                </button>
                <button
                  onClick={() => sortAfterClicked(5)}
                  className="sort-after-button"
                >
                  Number of Reviews
                </button>
              </div>
            )}
          </div>
        </div>

        <div className="other-store-section-container">
          <div className="ui grid container mobile">
            {viewMoreMobile ? renderList : renderList.slice(0, 24)}
          </div>
        </div>

        <button
          onClick={() => {
            setViewMoreMobile(true);
          }}
          className="view-more"
        >
          view more
        </button>
      </>
    );
  }
};

export default ProductComponent;
