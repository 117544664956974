import "./BirdLogo.css";
function BirdLogo() {
  return (
    <div className="BirdLogo">
      <div class="bird-logo">
        <div class="face-logo">
          <div class="white-container-logo">
            <div class="white-logo"></div>
          </div>
          <div class="shadow-container-logo">
            <div class="shadow-logo"></div>
          </div>
          <div class="bigBeak-container-logo">
            <div class="bigBeak-logo"></div>
          </div>
          <div class="smallBeak-container-logo">
            <div class="smallBeak-logo"></div>
          </div>
          <div class="eye-logo"></div>
        </div>
      </div>
    </div>
  );
}

export default BirdLogo;
