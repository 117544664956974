import React, { useEffect } from "react";
import NavbarMobile from "./navbarpages/NavbarMobile";
import "./HomePage.css";
function IOSInstallPage() {
  useEffect(() => {
    const videoPlayer = document.getElementsByClassName(
      "mobile-tutorial-video"
    )[0];

    // Function to change the playback speed
    function changePlaybackSpeed(speed) {
      videoPlayer.playbackRate = speed;
    }

    // Example: Change playback speed to 1.5x
    changePlaybackSpeed(0.8);

    return () => {};
  });

  return (
    <div className="mobile-body">
      <div className="mobile-front-page-header">
        <a href="/">
          <h1 className="mobile-logo">Pricein</h1>
        </a>
        <NavbarMobile></NavbarMobile>
      </div>
      <div className="mobile-content-body">
        <h2 className="mobile-title">Download with 3 easy steps:</h2>
        <div style={{ height: "20px" }}></div>
        <div className="mobile-features">
          <div className="stat-text">Tip:</div>
          <div className="normal-text">Follow Video Tutorial Down Below</div>
        </div>
        <div className="mobile-features">
          <div className="stat-text">1</div>
          <div className="normal-text">
            Click on "Download on the App Store"
          </div>
        </div>
        <div className="mobile-features">
          <div className="stat-text">2 </div>
          <div className="normal-text">
            Go to safari settings and then click on "extensions"
          </div>
        </div>
        <div className="mobile-features">
          <div className="stat-text">3</div>
          <div className="normal-text">
            Enable pricein extension and click on "All Websites" and click on
            "Allow"
          </div>
        </div>
        <div style={{ height: "20px" }}></div>
        <a
          className="add-to-phone-button"
          href="https://apps.apple.com/se/app/pricein-same-product-cheaper/id6455427237?l=en-GB"
        >
          Download on App Store
        </a>
        <div className="phone-video-body">
          <div className="phone-emu">
            <div className="greyspots">
              <div className="grey-dot" />
              <div className="grey-rect" />
            </div>
            <video
              class="mobile-tutorial-video"
              controls
              playsInline
              poster="/images/DownloadTutorial.webp"
            >
              <source src="/images/DownloadIOStutorial.mov" type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IOSInstallPage;
