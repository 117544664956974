import React, { useState } from "react";
import "./header.css";
import { FaSearch, FaImage } from "react-icons/fa";
import { MdCloudUpload } from "react-icons/md";
import BirdLogo from "./widgets/BirdLogo";
import { Link } from "react-router-dom";
import ReactGa from "react-ga4";
import ReactFlagsSelect from "react-flags-select";
const Header = (params) => {
  const mobileMode = params.mobileMode;
  const queryParams = new URLSearchParams(window.location.search);
  const imgParam = queryParams.get("img") || "";
  const ccParam = queryParams.get("cc") || "us";
  const titleParam = queryParams.get("title") || "product title";
  const [newImage, setNewImage] = useState(null);
  const [selectedButton, setSelectedButton] = useState(1);
  const [image, setImage] = useState(imgParam);

  const [inputSuggestions, setInputSuggestions] = useState();
  const [selectedCountry, setSelectedCountry] = useState(ccParam);
  const [inputText, setInputText] = useState(
    titleParam.split(" ").slice(0, 5).join(" ")
  );
  function onClick(btnIndex) {
    setSelectedButton(btnIndex);
  }

  function findProduct(title) {
    var img = image;
    console.log(img);

    if (title == "" && image != "") {
      window.open(
        "https://pricein.ai/product/finder/?title=" +
          title +
          "&img=" +
          img +
          "&imgonly=true" +
          "&cc=" +
          selectedCountry,
        "_self"
      );
    } else if (title != "" && image != "") {
      window.open(
        "https://pricein.ai/product/finder/?title=" +
          title +
          "&img=" +
          img +
          "&cc=" +
          selectedCountry,
        "_self"
      );
    } else if (title != "" && image == "") {
      window.open(
        "https://pricein.ai/product/finder/?title=" +
          title +
          "&img=null" +
          "&cc=" +
          selectedCountry,
        "_self"
      );
    }
  }
  function onChangeHandler(text) {
    console.log("fetching suggestion");
    setInputText(text);

    fetch(
      "https://server-dot-tryshopextension.uc.r.appspot.com/suggestions?text=" +
        text +
        "&l=en"
    )
      .then((reponse) => reponse.json())
      .then((result) => {
        console.log(result);
        setInputSuggestions(result);
      })
      .catch((err) => {});
  }
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      // Call your function here when Enter key is pressed
      // For example, you can call a submit function
      findProduct(inputText);
    }
  };

  if (!mobileMode) {
    return (
      <div
        className="menu"
        onClick={() => {
          if (inputSuggestions) {
            setInputSuggestions();
          }
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Link to="/">
            <div className="menu-logo">
              <h1 className="logo-text" href="/">
                Pricein
              </h1>
            </div>
          </Link>
          <div className="new-search-field">
            <div class="box">
              <FaSearch className="search-icon" />
              <input
                className="input-text-field-menu"
                type="text"
                name=""
                onKeyDown={handleKeyPress}
                value={inputText}
                placeholder={titleParam.split(" ").slice(0, 5).join(" ")}
                onChange={(e) => {
                  onChangeHandler(e.target.value);
                }}
              />

              {!image && (
                <form
                  action=""
                  className="img-icon add"
                  onClick={() => document.querySelector("#image-input").click()}
                >
                  <input
                    type="file"
                    accept="image/*"
                    id="image-input"
                    onChange={({ target: { files } }) => {
                      if (files) {
                        console.log(URL.createObjectURL(files[0]));
                        setImage(URL.createObjectURL(files[0]));
                      }
                    }}
                    hidden
                  />
                  <MdCloudUpload size={25} color="#1475cf"></MdCloudUpload>
                </form>
              )}
              {image != "null" && (
                <div className="img-icon">
                  <img
                    alt="product image"
                    src={image}
                    className="product-image"
                    onClick={() =>
                      document.querySelector("#image-input").click()
                    }
                  />

                  <img
                    alt="image on hover"
                    src={image}
                    className="image-on-hover-icon"
                  ></img>
                  <input
                    type="file"
                    accept="image/*"
                    id="image-input"
                    onChange={({ target: { files } }) => {
                      if (files) {
                        console.log(URL.createObjectURL(files[0]));
                        setImage(URL.createObjectURL(files[0]));
                      }
                    }}
                    hidden
                  />
                  <button
                    onClick={() => {
                      setImage(null);
                    }}
                  >
                    <img
                      alt="icon"
                      src="https://cdn-icons-png.flaticon.com/512/2997/2997911.png"
                    />
                  </button>
                </div>
              )}
            </div>
            {inputText.length != 0 && inputSuggestions ? (
              <div className="new-suggestions">
                {inputSuggestions.slice(0, 6).map((suggestion, i) => {
                  return (
                    <>
                      <div
                        onClick={() => {
                          findProduct(suggestion);
                          setInputText("");
                        }}
                        className="input-suggestion"
                        key={i}
                      >
                        {suggestion}
                      </div>
                    </>
                  );
                })}
              </div>
            ) : (
              <></>
            )}
          </div>
          <div onClick={findProduct} className="search-field-btn">
            <p>Search</p>
          </div>
          <ReactFlagsSelect
            className="react-select-flags-homepage"
            style={{ border: "none" }}
            selected={selectedCountry.toUpperCase()}
            onSelect={(code) => setSelectedCountry(code)}
            searchable
            showSelectedLabel={false}
            showOptionLabel={true}
            selectedSize={20}
          ></ReactFlagsSelect>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <a
            style={{
              marginRight: "100px",
              fontSize: "15px",
            }}
            href={"https://pricein.ai/extensionlink/"}
            target="_blank"
            className="new-download-extension"
          >
            Download Chrome Extension
          </a>
        </div>
      </div>
    );
  } else {
    return (
      <div className="menu-mobile">
        <Link to="/">
          <div className="menu-logo-mobile">
            <h1 className="logo-text" href="/">
              Pricein
            </h1>
          </div>
        </Link>
        <div class="box">
          <FaSearch className="search-icon" />
          <input
            className="input-text-field-menu"
            type="text"
            name=""
            placeholder={titleParam.split(" ").slice(0, 5).join(" ")}
          />

          {!image && (
            <form
              action=""
              className="img-icon add"
              onClick={() => document.querySelector("#image-input").click()}
            >
              <input
                type="file"
                accept="image/*"
                id="image-input"
                onChange={({ target: { files } }) => {
                  if (files) {
                    console.log(URL.createObjectURL(files[0]));
                    setImage(URL.createObjectURL(files[0]));
                  }
                }}
                hidden
              />
              <MdCloudUpload size={25} color="#1475cf"></MdCloudUpload>
            </form>
          )}
          {image && (
            <div className="img-icon">
              <img
                alt="product image"
                src={image}
                className="product-image"
                onClick={() => document.querySelector("#image-input").click()}
              />

              <img
                alt="hover icon"
                src={image}
                className="image-on-hover-icon"
              ></img>
              <input
                type="file"
                accept="image/*"
                id="image-input"
                onChange={({ target: { files } }) => {
                  if (files) {
                    console.log(URL.createObjectURL(files[0]));
                    setImage(URL.createObjectURL(files[0]));
                  }
                }}
                hidden
              />
              <button
                onClick={() => {
                  setImage(null);
                }}
              >
                <img
                  alt="icon"
                  src="https://cdn-icons-png.flaticon.com/512/2997/2997911.png"
                />
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
};

export default Header;
